import { Box, Heading, HStack, IconButton, Table, Tbody, Td, Th, Tr } from '@chakra-ui/react';
import { CgClose } from 'react-icons/cg';
import { convertUtcToLocal } from '../../../app/helpers/dateHelper';
import { DataProductMyAccessFirstDatasetModel } from '../../../app/services/dme/api/types';

type Props = {
  selectedAccess: DataProductMyAccessFirstDatasetModel;
  onClose: () => void;
};

const MyAccessDetail = ({ selectedAccess, onClose }: Props) => {
  return (
    <Box p={3} borderWidth="1px" borderRadius="lg" pb={5}>
      <HStack w="100%" justifyContent="space-between" alignItems="center" mb={3}>
        <Heading size="sm">Details</Heading>
        <IconButton
          variant="link"
          colorScheme="gray"
          size="md"
          aria-label="Close"
          icon={<CgClose />}
          title="Close"
          onClick={onClose}
        />
      </HStack>

      <Table size="sm">
        <Tbody
          sx={{
            '& tr > th': {
              maxW: '50px',
              whiteSpace: 'nowrap',
            },
          }}
        >
          <Tr>
            <Th>Method</Th>
            <Td>&nbsp;</Td>
            <Td>&nbsp;</Td>
            <Td>&nbsp;</Td>
            <Td>{selectedAccess.access_method}</Td>
            <Td>&nbsp;</Td>
            <Td>&nbsp;</Td>
            <Th>Status</Th>
            <Td>&nbsp;</Td>
            <Td>&nbsp;</Td>
            <Td>{selectedAccess.access_request_status}</Td>
            <Td>&nbsp;</Td>
          </Tr>
          <Tr>
            <Th>Squad</Th>
            <Td>&nbsp;</Td>
            <Td>&nbsp;</Td>
            <Td>&nbsp;</Td>
            <Td>{selectedAccess.domain_name}</Td>
            <Td>&nbsp;</Td>
            <Td>&nbsp;</Td>
            <Th whiteSpace="initial">Expiration Date</Th>
            <Td>&nbsp;</Td>
            <Td>&nbsp;</Td>
            <Td>{convertUtcToLocal(selectedAccess.access_expiration_datetime_utc, 'MMMM D, YYYY h:mm A')}</Td>
            <Td>&nbsp;</Td>
          </Tr>
          <Tr>
            <Th>Data Product Name</Th>
            <Td>&nbsp;</Td>
            <Td>&nbsp;</Td>
            <Td>&nbsp;</Td>
            <Td>{selectedAccess.data_product_name}</Td>
            <Td>&nbsp;</Td>
            <Td>&nbsp;</Td>
            <Th>Delivery Mode</Th>
            <Td>&nbsp;</Td>
            <Td>&nbsp;</Td>
            <Td>{selectedAccess.delivery_mode}</Td>
            <Td>&nbsp;</Td>
          </Tr>
        </Tbody>
      </Table>
    </Box>
  );
};

export default MyAccessDetail;
