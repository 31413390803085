import {
  Box,
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  TabPanels,
  Tabs,
  Text,
  Textarea,
  Tooltip,
  UseToastOptions,
  VStack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import { FC, createElement, useEffect, useState } from 'react';
import { BsFillPencilFill, BsFillPeopleFill } from 'react-icons/bs';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { AppAccess } from '../../../app/constants/appAccesses';
import { addBreadcrumb } from '../../../app/helpers/navigationHelpers';
import { useIsUserHasRequiredAppAccess } from '../../../app/hooks/useIsUserHasRequiredRoles';
import {
  useGetCustomRequestSelectedDetailsQuery,
  usePostCustomRequestStatusMutation,
  usePutCustomRequestClaimMutation,
} from '../../../app/services/dme/api/customRequest';
import { useGetRefRequestStatusQuery } from '../../../app/services/dme/api/refRequestStatus';
import { useAppSelector } from '../../../app/state/hooks';
import { customRequestNotesMaxLength } from '../constants';
import CustomRequestsSelectedDetails from './Details';
import CustomRequestsSelectedDiscussion from './Discussion';
import CustomRequestsSelectedStatus from './Status';
import { useGetUserListByRoleNameQuery } from '../../../app/services/dme/api/user';

const tabs = [
  {
    label: 'Details',
    path: 'details',
    component: CustomRequestsSelectedDetails,
  },
  {
    label: 'Status',
    path: 'status',
    component: CustomRequestsSelectedStatus,
  },
  {
    label: 'Discussion',
    path: 'discussion',
    component: CustomRequestsSelectedDiscussion,
  },
];

export type CustomRequestsSelectedProp = {
  page: 'custom-requests' | 'admin';
};

const CustomRequestsSelected = ({ page }: CustomRequestsSelectedProp) => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { logonUser } = useAppSelector(s => s.user);

  const customRequestId: number = parseInt(params.customRequestId || '0');
  const { data, isLoading, isFetching, isSuccess } = useGetCustomRequestSelectedDetailsQuery(customRequestId, {
    skip: !customRequestId,
  });

  const [tabIndex, setTabIndex] = useState<number | undefined>();

  const hasWriteAccess = useIsUserHasRequiredAppAccess(AppAccess.CustomRequestApprovalWrite);
  const hasAccess =
    !location.pathname.includes('my-requests') ||
    (location.pathname.includes('my-requests') && (logonUser?.ref_user_id ?? -1) === (data?.ref_requestor_id ?? -2));

  useEffect(() => {
    if (data && logonUser && isSuccess && !hasAccess) navigate('/custom-requests/my-requests');
  }, [data, hasAccess]);

  useEffect(() => {
    addBreadcrumb({ label: 'Selected', path: location.pathname });
  }, [location]);

  useEffect(() => {
    if (tabIndex !== undefined && params.selectedTab !== tabs[tabIndex].path) {
      navigate('./../' + tabs[tabIndex].path + location.search);
    }
  }, [tabIndex]);

  useEffect(() => {
    const paramTab = params.selectedTab;
    if (paramTab !== undefined) {
      const pathIndex = tabs.findIndex(f => f.path === paramTab);
      pathIndex !== tabIndex && setTabIndex(pathIndex);
    }
  }, [params]);

  const handleTabsChange = (index: number) => {
    setTabIndex(index);
  };

  return (isLoading || isFetching) && !data ? (
    <>Loading...</>
  ) : !hasAccess ? (
    <></>
  ) : (
    <VStack w="100%">
      {page === 'admin' && hasWriteAccess && data && <ChangeButtons details={data} />}
      {tabIndex !== undefined && (
        <Tabs index={tabIndex} onChange={handleTabsChange}>
          <TabList>
            {tabs.map((m, i) => (
              <Tab key={i}>{m.label}</Tab>
            ))}
          </TabList>
          <TabPanels>
            <Box p={3}>{tabs[tabIndex] && createElement(tabs[tabIndex].component)}</Box>
          </TabPanels>
        </Tabs>
      )}
    </VStack>
  );
};

const _toastOptionsError: UseToastOptions = {
  description: 'Something went wrong, please try again later or contact admin',
  status: 'error',
};

type ChangeButtonsProps = {
  details: {
    cr_custom_request_id: number;
    request_title: string;
    current_status: string;
    claimed_user_id: number;
  };
};

const ChangeButtons: FC<ChangeButtonsProps> = ({ details }) => {
  const [selectedStatus, setSelectedStatus] = useState<{ value: number; label: string } | null>(null);
  const [requestNotes, setRequestNotes] = useState<string>('');
  const { logonUser } = useAppSelector(s => s.user);

  const {onClose } = useDisclosure();
  const toast = useToast();

  const statusDetail = useGetRefRequestStatusQuery();
  const [postAsync, postDetail] = usePostCustomRequestStatusMutation();

  // New state for handling assignment
  const [selectedApprover, setSelectedApprover] = useState<{ value: number; label: string } | null>(null);
  const [claimAsync] = usePutCustomRequestClaimMutation();
  const approversDetail = useGetUserListByRoleNameQuery('DME Custom Request Admin');

  // for modal
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);
  const [isAssigneeModalOpen, setIsAssigneeModalOpen] = useState(false);

  const handleClose = () => {
    onClose();
    setSelectedStatus(null);
    setRequestNotes('');
    setIsStatusModalOpen(false);
    setIsAssigneeModalOpen(false);
    setSelectedApprover(null);
  };

  // Corrected handleApproverSelect to directly update the state
  const handleApproverSelect = (selectedOption: { value: number; label: string } | null) => {
    if (selectedOption) {
      setSelectedApprover(selectedOption); // Set selected approver ID
    } else {
        setSelectedApprover(null); // Reset if no approver is selected
    }
  };

  const handleSave = () => {
    if (!selectedStatus) return;

    postAsync({
      cr_custom_request_id: details.cr_custom_request_id,
      ref_request_status_id: selectedStatus.value,
      notes: requestNotes,
    })
      .unwrap()
      .then(() => {
        toast({ description: `Request Status changed to ${selectedStatus?.label.toLowerCase()}`, status: 'success' });
        handleClose();
      })
      .catch(() => {
        toast(_toastOptionsError);
      });
  };

  const handleAssigneeSave = () => {
    if (selectedApprover) {
      onClose();
      claimAsync({
        cr_custom_request_id: details.cr_custom_request_id,
        claimed_flag: true,
        ref_user_id: selectedApprover.value, // Use the selected approver ID
      })
      .unwrap()
      .then(() => {
        toast({ 
          description: `Request assigned to approver successfully`, 
          status: 'success',
          duration: 5000, //Show for 5 seconds
          isClosable: true, //Allows user to close the toast
         });
        handleClose();
      })
      .catch(() => {
        toast(_toastOptionsError);
      });
    }
  };

  if(details.current_status === 'Fulfilled' || details.current_status === 'Denied'){
    return null;
  }

  return (
    <>
      <HStack>
        <Tooltip label={'Current status: ' + details.current_status}>
          <Button leftIcon={<BsFillPencilFill />} w="fit-content" colorScheme="brand.main" size="sm" onClick={() => {setIsStatusModalOpen(true);}}>
            Change Status
          </Button>
        </Tooltip>

          <Button leftIcon={<BsFillPeopleFill />} w="fit-content" colorScheme="brand.main" size="sm" onClick={() => {setIsAssigneeModalOpen(true);}}>
            Change Assignee
          </Button>
      </HStack>

      {/* Change Status */}
      <Modal isOpen={isStatusModalOpen} onClose={handleClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Change Status</ModalHeader>
          <ModalCloseButton onClick={handleClose} isDisabled={postDetail.isLoading} />
          <ModalBody>
            <VStack gap={4}>
              <Box>
                <Text fontWeight="bold" as="span">
                  Title
                </Text>
                <Text>{details?.request_title}</Text>
              </Box>

              <Box>
                <Text fontWeight="bold">Status</Text>
                {!!statusDetail.data && (
                  <Select
                    size="sm"
                    useBasicStyles
                    placeholder="Select status..."
                    value={selectedStatus}
                    options={statusDetail.data
                      .filter(f => f.ref_request_status_id !== 1)
                      .map(m => ({
                        label: m.request_status_name,
                        value: m.ref_request_status_id,
                        isDisabled: m.request_status_name.toLowerCase() === details?.current_status.toLowerCase(),
                      }))}
                    onChange={e => setSelectedStatus(e)}
                    isDisabled={postDetail.isLoading}
                  />
                )}
              </Box>

              <Box>
                <HStack justifyContent="space-between" w="100%">
                  <Text fontWeight="bold">Notes</Text>
                  <Text>
                    {requestNotes.length > 0 && (
                      <Text as="small">{`${requestNotes.length}/${customRequestNotesMaxLength}`}</Text>
                    )}
                  </Text>
                </HStack>
                <Textarea
                  size="sm"
                  id="requestNotes"
                  name="requestNotes"
                  onChange={e => setRequestNotes(e.target.value)}
                  onBlur={e => setRequestNotes(e.target.value.trim())}
                  value={requestNotes}
                  maxLength={customRequestNotesMaxLength}
                  isDisabled={postDetail.isLoading}
                />
              </Box>
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button mr={3} onClick={handleClose} isDisabled={postDetail.isLoading}>
              Cancel
            </Button>
            <Button
              colorScheme="brand.main"
              isDisabled={!selectedStatus}
              onClick={handleSave}
              isLoading={postDetail.isLoading}
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      
      {/* Change Assignee Modal */}
      <Modal isOpen={isAssigneeModalOpen} onClose={handleClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Change Assignee</ModalHeader>
          <ModalCloseButton onClick={handleClose} />
          <ModalBody>
            <VStack gap={4}>
              <Box>
                <Text fontWeight="bold" as="span">
                  Title
                </Text>
                <Text>{details?.request_title}</Text>
              </Box>

              <Box>
                <Text fontWeight="bold">Reassign to new Admin?</Text>
                  <Select
                    size="sm"
                    useBasicStyles
                    placeholder="Select assignee..."
                    value={selectedApprover}
                    options={approversDetail.data
                      ?.filter(f => f.ref_user_id !== details?.claimed_user_id)
                      ?.map(m => ({
                        label: m.first_name+ ' ' +m.last_name,
                        value: m.ref_user_id,
                      }))}
                    onChange={handleApproverSelect}
                    isDisabled={postDetail.isLoading}
                  />
              </Box>

            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button mr={3} onClick={handleClose}>
              Cancel
            </Button>
            <Button colorScheme="brand.main" isDisabled={!selectedApprover} 
              onClick={() => {
                if(selectedApprover) {
                  handleAssigneeSave()
                }
              }}
              >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CustomRequestsSelected;
