import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetCustomRequestSelectedDetailsQuery } from '../../../app/services/dme/api/customRequest';
import { HStack, Heading, Table, Tbody, Td, Th, Tr, VStack, Text } from '@chakra-ui/react';
import { convertUtcToLocal } from '../../../app/helpers/dateHelper';

const CustomRequestsSelectedDetails = () => {
  const params = useParams();
  const [requestId, setRequestId] = useState(0);

  const { data, isLoading } = useGetCustomRequestSelectedDetailsQuery(requestId, { skip: !requestId });

  useEffect(() => {
    setRequestId(+(params?.customRequestId || 0));
  }, [params]);

  return (
    <VStack spacing={5} p={1} pt={3}>
      <Heading size="sm">Request Details</Heading>
      {isLoading ? (
        <p>Loading...</p>
      ) : !data ? null : (
        <HStack>
          <Table size="sm">
            <Tbody
              sx={{
                '& tr > th': {
                  maxW: '70px',
                  whiteSpace: 'nowrap',
                },
                td: {
                  whiteSpace: 'initial',
                  maxW: '300px',
                },
              }}
            >
              <Tr>
                <Th>ID</Th>
                <Td>
                  <Text>{data.cr_custom_request_id}</Text>
                </Td>
                <Td>&nbsp;</Td>
                <Th>Current Status</Th>
                <Td>{data.current_status}</Td>
              </Tr>
              <Tr> 
                <Th>Requestor</Th>
                <Td>{(data.first_name ?? '') + ' ' + (data.last_name ?? '')}</Td>
                <Td>&nbsp;</Td>
                <Th>Assignee</Th>
                <Td>
                  {data.claimed_user_name? data.claimed_user_name: '-'}
                </Td>
              </Tr>
              <Tr>
              <Th>Title</Th>
                <Td>
                  <Text title={data.request_title}>{data.request_title}</Text>
                </Td>
                <Td>&nbsp;</Td>
                <Th minW={90}>Request Date</Th>
                <Td whiteSpace="nowrap">
                  {convertUtcToLocal(data.request_datetime_utc, 'MMMM D, YYYY h:mm A') || 'N/A'}
                </Td>
              </Tr>
              <Tr>
                <Th>Description</Th>
                <Td>
                  <Text whiteSpace="pre-wrap" title={data.request_details}>
                    {data.request_details}
                  </Text>
                </Td>
                <Td>&nbsp;</Td>
                <Th minW={90}>Expiration Date</Th>
                <Td>{convertUtcToLocal(data.request_expiration_datetime_utc, 'MMMM D, YYYY h:mm A') || 'N/A'}</Td>
              </Tr>
              <Tr>
                <Th minW={100}>Notes</Th>
                <Td colSpan={4}>
                  <Text whiteSpace="pre-wrap" title={data.request_notes}>
                    {data.request_notes? data.request_notes: '-'}
                  </Text>
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </HStack>
      )}
    </VStack>
  );
};

export default CustomRequestsSelectedDetails;
