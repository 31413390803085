import { DynamicObject } from '../../../types/appType';
import { dmeApi } from './index';
import {
  DataProductModel,
  DataProductMostPopularModel,
  DataProductMyAccessDetailModel,
  DataProductMyAccessModel,
  DataProductResultModel,
  DataProductResultsPageFilterModel,
  DataProductResultsPageFilterTransformedModel,
  DataProductSelectPageDetailsModel,
  DataProductSelectPageModesModel,
  DataProductSelectPagePayloadElementTagsModel,
  DataProductSelectPageRolesModel,
  DataProductSelectPageTagsModel,
  DataProductSelectPageUsersModel,
  DataProductServiceAccountsModel,
  DataProductStateModel,
  DataProductSummaryModel,
  DeliveryModeAdditionalFieldsModel,
  FilterItem,
  GetDataProductResultDto,
  GetDataProductResultModel,
  PostRequestAccessForRoleModel,
  PostRequestAccessForSaModel,
  PostRequestAccessForUserModel,
  PutRequestAccessForRoleModel,
  PutRequestAccessForSaModel,
  PutRequestAccessForUserModel,
  RequestAccesRoleUserDto,
  RequestAccesRoleUserModel,
  RequestAccessModeModel,
} from './types';

const extendedApi = dmeApi.injectEndpoints({
  endpoints: builder => ({
    getDeliveryModeAdditionalFields: builder.query<DeliveryModeAdditionalFieldsModel[], number>({
      query: delivery_mode_id => `/dataproduct/additionalfields/delivery_mode_id/${delivery_mode_id}`,
    }),

    // SP: p_dp_data_product_unique_filter
    getDataProductResultsPageFilter: builder.query<
      { data: DataProductResultsPageFilterTransformedModel[]; rawData: DataProductResultsPageFilterModel[] },
      void
    >({
      query: () => `/dataproduct/result/filter`,
      providesTags: ['DataProductResultsPageFilter'],
      transformResponse: (response: DataProductResultsPageFilterModel[]) => {
        const a = response.reduce((prev, { filter_header, filter_ref_id, filter_detail, filter_order }) => {
          const existingItemIndex = prev.findIndex(
            s => s.filter_header === filter_header && s.filter_order === filter_order,
          );

          if (existingItemIndex > -1) {
            let prevFilterItems = prev[existingItemIndex].filter_items;

            const existingFilterItemsIndex = prevFilterItems.findIndex(s => s.filter_detail === filter_detail);
            if (existingFilterItemsIndex > -1) {
              prevFilterItems[existingFilterItemsIndex] = {
                filter_header,
                filter_detail,
                filter_ref_id,
              };
            } else {
              prevFilterItems = [
                ...prevFilterItems,
                {
                  filter_header,
                  filter_detail,
                  filter_ref_id,
                },
              ];
            }

            const sortedFilterItems = [...prevFilterItems].sort((a, b) => {
              if (a.filter_detail > b.filter_detail) {
                return 1;
              }
              if (b.filter_detail > a.filter_detail) {
                return -1;
              }
              return 0;
            });
            prev[existingItemIndex] = {
              ...prev[existingItemIndex],
              filter_items: sortedFilterItems,
            };

            return [...prev];
          }

          return [
            ...prev,
            {
              filter_order,
              filter_header,
              filter_items: [
                {
                  filter_header,
                  filter_detail,
                  filter_ref_id,
                },
              ],
            },
          ];
        }, [] as DataProductResultsPageFilterTransformedModel[]);
        return { data: a, rawData: response };
      },
    }),
    getDataProductSummary: builder.query<Array<DataProductSummaryModel>, void>({
      query: () => `/dataproduct/summary`,
      providesTags: ['DataProductSummary'],
    }),
    getDataProductMostPopular: builder.query<Array<DataProductMostPopularModel>, void>({
      query: () => `/dataproduct/popular`,
      providesTags: ['DataProductMostPopular'],
    }),

    // SP: p_dp_data_products_explore_filters_applied
    getDataProductResult: builder.query<GetDataProductResultModel, GetDataProductResultDto>({
      query: ({ filters, ...params }) => ({
        url: `/dataproduct/result`,
        params: {
          ...params,
          ...filters.reduce((obj, val, i) => {
            obj[`filters[${i}].filter_order`] = val.filter_order;
            obj[`filters[${i}].filter_ref_id`] = val.filter_ref_id;
            return obj;
          }, {} as DynamicObject),
        },
      }),
      providesTags: ['DataProductResult'],
    }),

    getDataProductResultByFilter: builder.query<Array<DataProductResultModel>, FilterItem[]>({
      query: filter => ({
        url: `/dataproduct/result/filtered`,
        params: { filter: JSON.stringify(filter) },
      }),
      providesTags: ['DataProductResultFiltered'],
    }),

    // DB View: vw_dp_data_product_select_page_details_tab
    getDataProductSelectDetailByProductId: builder.query<DataProductSelectPageDetailsModel, number>({
      query: productId => `/dataproduct/select/detail/${productId}`,
    }),
    getDataProductSelectModesByProductId: builder.query<Array<DataProductSelectPageModesModel>, number>({
      query: productId => `/dataproduct/select/mode/${productId}`,
      transformResponse: (response: { mode: DataProductSelectPageModesModel[] }) => {
        return response.mode.map(m => ({ ...m, is_deleted_flag: false }));
      },
    }),
    getDataProductSelectTagsByProductId: builder.query<Array<DataProductSelectPageTagsModel>, number>({
      query: productId => `/dataproduct/select/tags/${productId}`,
      transformResponse: (response: DataProductSelectPageTagsModel[]) => {
        return response.map(m => ({ ...m, is_deleted_flag: false }));
      },
    }),
    getDataProductSelectPayloadElementTagsByProductId: builder.query<
      Array<DataProductSelectPagePayloadElementTagsModel>,
      number
    >({
      query: productId => `/dataproduct/select/payload-element-tags/${productId}`,
      transformResponse: (response: DataProductSelectPagePayloadElementTagsModel[]) => {
        const ret = response.reduce(function (
          r: DataProductSelectPagePayloadElementTagsModel[],
          o: DataProductSelectPagePayloadElementTagsModel,
        ) {
          o.is_deleted_flag = false;
          o.ref_tag_ids = o?.ref_tag_id ? [o?.ref_tag_id] : [];
          let dataElementExistIndex = r.findIndex(f => f.data_element === o.data_element);
          if (dataElementExistIndex > -1) {
            r[dataElementExistIndex].data_element_tag += ', ' + o.data_element_tag;
            if (o?.ref_tag_id) {
              r[dataElementExistIndex].ref_tag_ids?.push(o.ref_tag_id);
            }
            return [...r];
          } else {
            return [...r, o];
          }
        }, []);
        return ret;
      },
    }),
    getDataProductSelectRolesByProductId: builder.query<Array<DataProductSelectPageRolesModel>, number>({
      query: productId => `/dataproduct/select/roles/${productId}`,
    }),
    getDataProductSelectUsersByProductId: builder.query<Array<DataProductSelectPageUsersModel>, number>({
      query: productId => `/dataproduct/select/users/${productId}`,
    }),

    // SP: p_dp_data_products_service_accounts_tab
    getDataProductSelectServiceAccountsByProductId: builder.query<Array<DataProductServiceAccountsModel>, number>({
      query: productId => `/dataproduct/serviceaccounts/dp_data_product_id/${productId}`,
    }),
    
    getDataProductMyAccess: builder.query<DataProductMyAccessModel, number>({
      query: refuserid => `/dataproduct/myaccess/refuserid/${refuserid}`,
      providesTags: ['MyAccess'],
    }),
    getDataProductMyAccessDetail: builder.query<
      DataProductMyAccessDetailModel,
      { ref_user_id: number; access_method: string; request_id: number }
    >({
      query: params =>
        `/dataproduct/myaccess/refuserid/${params.ref_user_id}/accessmethod/${params.access_method}/requestid/${params.request_id}`,
    }),

    getRequestAccessRoleUserByEmail: builder.query<Array<RequestAccesRoleUserModel>, RequestAccesRoleUserDto>({
      query: ({ email, ...params }) => ({
        url: `/dataproduct/requestaccess/roleuser/${email}`,
        params,
      }),
    }),

    getRequestAccessModeByDataProductId: builder.query<Array<RequestAccessModeModel>, number>({
      query: dp_data_product_id => `/dataproduct/requestaccess/mode/${dp_data_product_id}`,
    }),

    getDataProductListByRefDomainId: builder.query<Array<DataProductModel>, number>({
      query: ref_domain_id => `/dataproduct/refdomainid/${ref_domain_id}`,
    }),

    getDataProductStateList: builder.query<Array<DataProductStateModel>, void>({
      query: () => '/dataproduct/dp_data_product_state/list',
    }),

    postRequestAccess: builder.mutation<
      void,
      PostRequestAccessForRoleModel | PostRequestAccessForUserModel | PostRequestAccessForSaModel
    >({
      query: params => ({
        url: `dataproduct/requestaccess/${
          'ref_role_id' in params ? 'role' : 'ref_user_id' in params ? 'user' : 'serviceaccount'
        }`,
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['MyAccess'],
    }),

    putRoleRequestAccess: builder.mutation<void, PutRequestAccessForRoleModel>({
      query: params => ({
        url: '/dataproduct/requestaccess/role',
        method: 'PUT',
        body: params,
      }),
      invalidatesTags: ['MyAccess'],
    }),

    putUserRequestAccess: builder.mutation<void, PutRequestAccessForUserModel>({
      query: params => ({
        url: '/dataproduct/requestaccess/user',
        method: 'PUT',
        body: params,
      }),
      invalidatesTags: ['MyAccess'],
    }),

    putSaRequestAccess: builder.mutation<void, PutRequestAccessForSaModel>({
      query: params => ({
        url: '/dataproduct/requestaccess/serviceaccount',
        method: 'PUT',
        body: params,
      }),
      invalidatesTags: ['MyAccess'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useLazyGetDeliveryModeAdditionalFieldsQuery,
  useGetDataProductResultsPageFilterQuery,
  useGetDataProductSummaryQuery,
  useGetDataProductMostPopularQuery,
  useGetDataProductResultQuery,
  useGetDataProductSelectDetailByProductIdQuery,
  useGetDataProductSelectModesByProductIdQuery,
  useGetDataProductSelectTagsByProductIdQuery,
  useGetDataProductSelectPayloadElementTagsByProductIdQuery,
  useGetDataProductSelectRolesByProductIdQuery,
  useGetDataProductSelectUsersByProductIdQuery,
  useGetDataProductSelectServiceAccountsByProductIdQuery,
  useGetDataProductMyAccessQuery,
  useLazyGetDataProductResultQuery,
  useLazyGetDataProductResultByFilterQuery,
  useGetDataProductMyAccessDetailQuery,
  useLazyGetDataProductMyAccessDetailQuery,
  useLazyGetDataProductListByRefDomainIdQuery,

  useGetRequestAccessRoleUserByEmailQuery,
  useGetRequestAccessModeByDataProductIdQuery,
  useLazyGetRequestAccessRoleUserByEmailQuery,
  useLazyGetRequestAccessModeByDataProductIdQuery,

  useLazyGetDataProductSelectDetailByProductIdQuery,
  useLazyGetDataProductSelectModesByProductIdQuery,
  useLazyGetDataProductSelectTagsByProductIdQuery,
  useLazyGetDataProductSelectPayloadElementTagsByProductIdQuery,
  useLazyGetDataProductSelectRolesByProductIdQuery,
  useLazyGetDataProductSelectUsersByProductIdQuery,

  useGetDataProductStateListQuery,

  usePostRequestAccessMutation,
  usePutRoleRequestAccessMutation,
  usePutUserRequestAccessMutation,
  usePutSaRequestAccessMutation,
} = extendedApi;
