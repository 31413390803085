import { WarningIcon } from '@chakra-ui/icons';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  ButtonGroup,
  Divider,
  Heading,
  HStack,
  Icon,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import _ from 'lodash';
import { serialize } from 'object-to-formdata';
import { createElement, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { addBreadcrumb, sliceBreadcrumb } from '../../../../app/helpers/navigationHelpers';
import { ValueOf } from '../../../../app/helpers/utilities';
import {
  useGetDataProductStateListQuery,
  useLazyGetDataProductSelectDetailByProductIdQuery,
  useLazyGetDataProductSelectModesByProductIdQuery,
  useLazyGetDataProductSelectPayloadElementTagsByProductIdQuery,
  useLazyGetDataProductSelectTagsByProductIdQuery,
} from '../../../../app/services/dme/api/dataProduct';
import { usePutDataProductMutation } from '../../../../app/services/dme/api/editorDataProduct';
import {
  DataProductSelectPageDetailsModel,
  DataProductSelectPageModesForViewModel,
  DataProductSelectPageModesModel,
  DataProductSelectPagePayloadElementTagsModel,
  DataProductSelectPageTagsModel,
} from '../../../../app/services/dme/api/types';
import { EditDataProductModel, LineageModel } from '../../../../app/services/types';
import { useAppSelector } from '../../../../app/state/hooks';
import DataProductSelectedDetails from './Details';
import DataProductSelectedLineage from './Lineage';
import DataProductSelectedMode from './Mode';
import DataProductSelectedPayloadElementTag from './PayloadElementTag';
import DataProductSelectedTags from './Tags';

type Props = {};

const EditDataProductSelected = (props: Props) => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();
  const [tabIndex, setTabIndex] = useState<number | undefined>();
  const { logonUser } = useAppSelector(s => s.user);
  const [isLoading, setIsLoading] = useState(true);
  const [dpId, setdpId] = useState(0);
  const [localError, setLocalError] = useState<{ title: string; desc: string }>();
  const [localSuccess, setLocalSuccess] = useState<string>();
  const [tabErrorIndex, setTabErrorIndex] = useState<number>(-1);
  const [putAsync, putDetail] = usePutDataProductMutation();

  const [triggerDetails, { data: detailsData, isLoading: isLoadingDetails, isFetching: isFetchingDetails }] =
    useLazyGetDataProductSelectDetailByProductIdQuery();

  const [triggerPayload, { data: payloadData, isLoading: isLoadingPayload, isFetching: isFetchingPayload }] =
    useLazyGetDataProductSelectPayloadElementTagsByProductIdQuery();
  const [triggerModes, { data: modesData, isLoading: isLoadingMode, isFetching: isFetchingMode }] =
    useLazyGetDataProductSelectModesByProductIdQuery();
  const [triggerTags, { data: tagsData, isLoading: isLoadingTags, isFetching: isFetchingTags }] =
    useLazyGetDataProductSelectTagsByProductIdQuery();

  const { data: stateData } = useGetDataProductStateListQuery();

  const tabs = [
    {
      label: 'Details',
      path: 'details',
      component: DataProductSelectedDetails,
      onChange: (data: DataProductSelectPageDetailsModel, isReset?: boolean) => {
        setValues({ ...values, detailModel: isReset ? detailsData : data });
      },
      model: 'detailModel',
      isArray: false,
    },
    {
      label: 'Payload and Element Tags',
      path: 'payload-element-tags',
      component: DataProductSelectedPayloadElementTag,
      model: 'payloadModel',
    },
    {
      label: 'Lineage',
      path: 'lineage',
      component: DataProductSelectedLineage,
      model: 'lineageModel',
    },
    {
      label: 'Mode',
      path: 'mode',
      component: DataProductSelectedMode,
      model: 'modeModel',
    },
    {
      label: 'Tags',
      path: 'tags',
      component: DataProductSelectedTags,
      model: 'tagModel',
    },
  ];

  const FormSchema = Yup.object().shape({
    data_product_id: Yup.number().moreThan(0).required(),
    detailModel: Yup.object().shape({
      data_product_name: Yup.string().label('Name').required(),
      data_product_desc: Yup.string().label('Description').required(),
      ref_domain_id: Yup.number().moreThan(0).label('Squad').required(),
      data_product_type_id: Yup.number().moreThan(0).label('Product Type').required(),
      developer_user_id: Yup.number().moreThan(0).label('Developer').required(),
      dp_data_product_state_id: Yup.number().moreThan(0).label('State').required(),
      updateStatePostMessage: Yup.string()
        .label('Change State Message')
        .when(['dp_data_product_state_id'], {
          is: (dp_data_product_state_id: number) => {
            const isRequireChangeStateMessage = dp_data_product_state_id !== detailsData?.dp_data_product_state_id;
            return isRequireChangeStateMessage;
          },
          then: schema => schema.required().min(1),
          otherwise: schema => schema.optional(),
        }),
    }),
    payloadModel: Yup.array<DataProductSelectPagePayloadElementTagsModel[]>()
      .of(
        Yup.object().shape({
          data_element: Yup.string().required().label('Data Element'),
          data_element_data_type: Yup.string().required().label('Data Type'),
          data_element_description: Yup.string().required().label('Description'),
          ref_tag_ids: Yup.array<number[]>().of(Yup.number().moreThan(0)),
        }),
      )
      .min(1, 'Payload and Elements must have at least 1 entry'),
    modeModel: Yup.array<DataProductSelectPageModesModel[]>()
      .of(
        Yup.object().shape({
          ref_delivery_mode_id: Yup.number().moreThan(0).required().label('Mode'),
          properties: Yup.string().required().label('Properties'),
          ref_refresh_frequency_id: Yup.number().moreThan(0).required().label('Refresh Frequency'),
          modesForView: Yup.array<DataProductSelectPageModesForViewModel[]>().of(
            Yup.object().shape({
              ref_delivery_mode_id: Yup.number().moreThan(0).required().label('Mode'),
              properties: Yup.string().required().label('Properties'),
            }),
          ),
        }),
      )
      .min(1, 'Modes must have at least 1 entry'),
    tagModel: Yup.array<DataProductSelectPageTagsModel[]>().of(
      Yup.object().shape({
        ref_tag_id: Yup.number().moreThan(0).required().label('Tag'),
      }),
    ),
    // .min(1, "Tags must have at least 1 entry"),
  });

  const defaultValue = {
    data_product_id: +(params?.dataProductId || 0),
    detailModel: detailsData,
    payloadModel: payloadData,
    lineageModel: {
      dp_lineage_filename: detailsData?.data_product_lineage_filename,
      dp_lineage_file: null,
    } as LineageModel,
    modeModel: modesData,
    tagModel: tagsData,
  };

  const { handleSubmit, errors, touched, values, resetForm, setValues, validateForm, initialValues } = useFormik({
    enableReinitialize: true,
    validationSchema: FormSchema,
    initialValues: {
      data_product_id: defaultValue.data_product_id,
      detailModel: defaultValue.detailModel,
      payloadModel: defaultValue.payloadModel,
      lineageModel: defaultValue.lineageModel,
      modeModel: defaultValue.modeModel,
      tagModel: defaultValue.tagModel,
    },

    onSubmit: values => {},
  });

  const validate = async () => {
    setLocalError(undefined);
    handleSubmit(); // will trigger errors

    const validationError = await validateForm(values);
    const tempError = validationError as any;

    if (Object.keys(validationError).length > 0) {
      if (
        validationError.detailModel ||
        Array.isArray(validationError.payloadModel) ||
        Array.isArray(validationError.modeModel) ||
        Array.isArray(validationError.tagModel)
      ) {
        setLocalError({ title: '', desc: 'Some field(s) are invalid' });
      } else {
        setLocalError({
          title: '',
          desc:
            tempError.detailModel?.toString() ||
            tempError.payloadModel?.toString() ||
            tempError.lineageModel?.toString() ||
            tempError.modeModel?.toString() ||
            tempError.tagModel?.toString(),
        });
      }
    } else {
      await submit();
    }
  };

  const handleTabsChange = (index: number) => {
    setTabIndex(index);
    putDetail.error && setLocalError(undefined);
  };

  const onChange = (field: keyof EditDataProductModel, data: ValueOf<EditDataProductModel>, isReset?: boolean) => {
    let defaultData;
    let tempIndex = 0;
    switch (field) {
      case 'detailModel':
        defaultData = detailsData;
        break;
      case 'payloadModel':
        defaultData = payloadData;
        tempIndex = 1;
        break;
      case 'lineageModel':
        defaultData = {
          dp_lineage_filename: detailsData?.data_product_lineage_filename,
          dp_lineage_file: null,
        } as LineageModel;
        tempIndex = 2;
        break;
      case 'modeModel':
        defaultData = modesData;
        tempIndex = 3;
        break;
      case 'tagModel':
        defaultData = tagsData;
        tempIndex = 4;
        break;
    }
    setValues({
      ...values,
      [field]: isReset ? defaultData : data,
    });

    if ((isReset && Object.keys(errors).length == 0) || tabErrorIndex == tempIndex) {
      setLocalError(undefined);
      setTabErrorIndex(-1);
    }
  };

  const submit = async () => {
    const finalValues = { ...values, createPostModel: null as any };
    //compare original values to identify deleted items
    if (payloadData && finalValues.payloadModel) {
      const deletedPayload = getDeletedItem(payloadData, finalValues.payloadModel, 'dp_data_product_payload_id');
      finalValues.payloadModel = finalValues.payloadModel.concat(
        deletedPayload as DataProductSelectPagePayloadElementTagsModel[],
      );
      finalValues.payloadModel = finalValues.payloadModel.map((m, i) => ({ ...m, data_element_order: i }));
    }

    if (modesData && finalValues.modeModel) {
      const deletedModes = getDeletedItem(modesData, finalValues.modeModel, 'dp_data_product_delivery_mode_id');
      finalValues.modeModel = finalValues.modeModel
        .concat(deletedModes as DataProductSelectPageModesModel[])
        .map(m1 => ({
          ...m1,
          deliverymodeforview: m1.modesForView.map(m2 => ({
            ref_delivery_mode_id: m2.ref_delivery_mode_id,
            delivery_mode_properties: m2.properties,
          })),
          modesForView: [], // not needed, better delete
        }));
    }

    if (tagsData && finalValues.tagModel) {
      const deletedTags = getDeletedItem(tagsData, finalValues.tagModel, 'dp_tag_data_product_id');
      finalValues.tagModel = finalValues.tagModel.concat(deletedTags as DataProductSelectPageTagsModel[]);
    }

    const changeStateMessage = (finalValues.detailModel as EditDataProductModel['detailModel'])?.updateStatePostMessage;
    if (
      stateData &&
      logonUser &&
      finalValues.detailModel?.dp_data_product_state_id &&
      changeStateMessage &&
      detailsData?.dp_data_product_state_id !== values.detailModel?.dp_data_product_state_id
    ) {
      const selectedState = stateData.find(
        f => f.dp_data_product_state_id === finalValues.detailModel?.dp_data_product_state_id,
      );

      finalValues.createPostModel = {
        post_subject: 'STATE UPDATE: ' + selectedState?.dp_state_name.toUpperCase(),
        post_message: '<p>' + changeStateMessage + '</p>',
      };
    }

    const formDataValues = serialize(finalValues, {
      indices: true,
      allowEmptyArrays: false,
      dotsForObjectNotation: true,
    });
    await putAsync(formDataValues);
  };

  const getDeletedItem = (originalData: any[], currentData: any[], key: string) => {
    return (
      originalData
        ?.filter(item => !currentData?.some(s => s[key] == item[key]))
        ?.map(m => ({ ...m, is_deleted_flag: true })) || []
    );
  };

  const getTabsData = async (dpId: number) => {
    setIsLoading(true);
    await triggerDetails(dpId);
    await triggerPayload(dpId);
    await triggerModes(dpId);
    await triggerTags(dpId);
    setIsLoading(false);
  };

  const onDuplicateError = (index: number) => {
    setTabIndex(index);
    setTabErrorIndex(index);
  };

  const onCancel = () => {
    const proceed = () => navigate('../edit-data-products');
    if (_.isEqual(values, defaultValue)) {
      proceed();
    } else {
      if (window.confirm('Changes you made will not be saved. Do you want to leave this page?')) {
        proceed();
      }
    }
  };

  useEffect(() => {
    if (detailsData?.disabled_flag) {
      toast({ description: 'Data product already deleted', status: 'error' });
      navigate('/editor-data-products', { replace: true });
    }
  }, [detailsData]);

  useEffect(() => {
    sliceBreadcrumb(0, 2);
    addBreadcrumb({ label: 'Selected', path: location.pathname });
  }, [location]);

  useEffect(() => {
    if (tabIndex !== undefined && params.selectedTab !== tabs[tabIndex].path) {
      navigate('./../' + tabs[tabIndex].path);
      window.scrollTo(0, 0);
    }
  }, [tabIndex]);

  useEffect(() => {
    const paramTab = params.selectedTab || '';

    if (paramTab.length > 0) {
      const pathIndex = tabs.findIndex(f => f.path === paramTab);
      pathIndex !== tabIndex && setTabIndex(pathIndex);
    }
  }, [params]);

  useEffect(() => {
    (async () => {
      const paramDpId = +(params?.dataProductId || 0);

      if (paramDpId > 0) {
        await getTabsData(paramDpId);
        setdpId(paramDpId);
      }
    })();
  }, [params.dataProductId]);

  useEffect(() => {
    (async () => {
      const { data, isSuccess, isError, isLoading } = putDetail;
      const resData: any = data;
      if (isSuccess && resData?.isSuccess) {
        setLocalSuccess('Data Product successfully updated.');
        await getTabsData(dpId);
        resetForm();
        setTimeout(() => {
          setLocalSuccess('');
        }, 3000);
      } else if (resData && !resData?.isSuccess) {
        let tabError = '';
        if (resData.msg.includes('dp_data_product_payload')) {
          onDuplicateError(1);
          tabError = 'Cannot insert duplicate Data Product Payload Name';
        } else if (resData.msg.includes('dp_data_product_delivery_mode')) {
          onDuplicateError(3);
          tabError = 'Cannot insert duplicate Data Product Mode';
        } else if (resData.msg.includes('dp_tag_data_product')) {
          onDuplicateError(4);
          tabError = 'Cannot insert duplicate Data Product Tag';
        } else if (resData.msg.includes('dp_data_product')) {
          onDuplicateError(0);
          tabError = 'Cannot insert duplicate Data Product Name';
        }
        setLocalError({
          title: '',
          desc: tabError,
        });
      } else if (isError) {
        setLocalError({
          title: '',
          desc: 'There was an error processing your request, please try again later.',
        });
      } else {
        setLocalError(undefined);
      }

      if (isLoading) {
        setLocalSuccess('');
      }
    })();
  }, [putDetail]);

  useEffect(() => {
    if (Object.keys(errors).length === 0) {
      setLocalError(undefined);
    }
  }, [errors]);

  if (detailsData?.disabled_flag) return null;

  return (
    <VStack w="100%">
      <HStack>
        <HStack flex={1}>
          <Heading size="md" whiteSpace="initial" noOfLines={1} maxW="800px" title={params.dataProduct}>
            {detailsData?.data_product_name ?? params.dataProduct}
          </Heading>
          {logonUser && (
            <ButtonGroup>
              <Button
                isLoading={putDetail.isLoading}
                variant="outline"
                colorScheme="brand.main"
                onClick={() => validate()}
              >
                Save
              </Button>
              <Button variant="solid" onClick={onCancel}>
                Cancel
              </Button>
            </ButtonGroup>
          )}
        </HStack>
        {localError && tabIndex !== undefined && (
          <Alert py={2} status="error">
            <AlertIcon />
            {localError.title && <AlertTitle>{tabs[tabIndex].label}</AlertTitle>}
            <AlertDescription>{localError.desc}</AlertDescription>
          </Alert>
        )}
        {localSuccess && (
          <Alert py={2} status="success">
            <AlertIcon />
            <AlertDescription>{localSuccess}</AlertDescription>
          </Alert>
        )}
      </HStack>
      <Divider />

      {tabIndex !== undefined && (
        <Tabs index={tabIndex} onChange={handleTabsChange}>
          <TabList>
            {tabs.map((m, i) => {
              const error = errors as any;
              return (
                <Tab key={i}>
                  {m.label}
                  {(error[m.model] && localError) || tabErrorIndex == i ? (
                    <Icon color="brand.error" ml={2} as={WarningIcon} />
                  ) : (
                    <></>
                  )}
                </Tab>
              );
            })}
          </TabList>
          <TabPanels>
            {tabs.map((tabPanel, i) => (
              <TabPanel key={i}>
                {tabPanel && values && !isLoading ? (
                  createElement(tabPanel.component, {
                    onChangeData: onChange,
                    initialValues,
                    data: values,
                    isSubmitting: putDetail.isLoading,
                    errors: errors,
                    touched: touched,
                    title: tabPanel.label,
                    tabErrorIndex: tabErrorIndex,
                  })
                ) : (
                  <>Loading...</>
                )}
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      )}
    </VStack>
  );
};

export default EditDataProductSelected;
