import { Box, Divider, Heading, Tab, TabList, TabPanels, Tabs, VStack } from '@chakra-ui/react';
import { createElement, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { AddPlatformServiceModel, PlatformServicesCreateRequestQAListFirstModel, PlatformServicesCreateRequestQAListSecondModel, QuestionTypeModel, PlatformServicesCreateRequestQAListModel } from '../../../../app/services/dme/api/types';
import { addBreadcrumb } from '../../../../app/helpers/navigationHelpers';
import { ExplorePlatfromServicesCreateRequestProps, explorePlatfromServicesCreateRequestTabs, initialValuesExplorePlatfromServicesCreateRequest } from './utils';
import { useGetPlatformServicesRequestCreationQaListQuery } from '../../../../app/services/dme/api/platformServices';

const ExplorePlatformServicesCreateRequest = () => {
  const params = useParams();
  const location = useLocation();
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [data, setData] = useState<AddPlatformServiceModel>(initialValuesExplorePlatfromServicesCreateRequest);
  const [questions, setQuestions] = useState<PlatformServicesCreateRequestQAListFirstModel[]>([]);
  const [referenceTableContents, setReferenceTableContents] = useState<PlatformServicesCreateRequestQAListSecondModel[]>([]);
  const original = location.state?.original || {};
  const psRefCategoryId: number = original.ps_ref_category_id;
  const { data: qaListDetails, isLoading, isFetching } = useGetPlatformServicesRequestCreationQaListQuery(
    { psRefCategoryId },
    { skip: psRefCategoryId === 0 }
  );

  useEffect(() => {
    addBreadcrumb({ label: 'Create Request', path: location.pathname });
  }, [location]);

  useEffect(() => {
    if (!qaListDetails?.first_dataset_model || qaListDetails?.first_dataset_model.length <= 0) return;

    const mapQuestionType = (type: string): QuestionTypeModel => {
      switch (type) {
        case 'Dropdown':
          return 'Dropdown';
        case 'Extended Open Text Box':
          return 'Extended Open Text Box';
        case 'Yes/No Questions':
          return 'Yes/No Questions';
        default:
          return 'Reference Table';
      }
    };

    const sortedQuestions = qaListDetails.first_dataset_model
      .map(question => ({
        ...question,
        question_type: mapQuestionType(question.question_type),
      }))
      .sort((a, b) => a.question_order - b.question_order);

    setQuestions(sortedQuestions);
    setData(d => ({
      ...d,
      answers: sortedQuestions.map(m => ({
        ps_ref_question_id: m.ps_ref_question_id,
      })),
    }));

    const referenceTableContents = qaListDetails.second_dataset_model;

    setReferenceTableContents(referenceTableContents);

  }, [qaListDetails?.first_dataset_model, qaListDetails?.second_dataset_model]);

  return (
    <VStack>
      <Heading size="md">Create Request</Heading>
      <Divider />

      <Tabs index={tabIndex}>
        <TabList>
          {explorePlatfromServicesCreateRequestTabs.map((m, i) => (
            <Tab key={i}>{m.label}</Tab>
          ))}
        </TabList>
        <TabPanels>
          <Box p={3}>
            {createElement<ExplorePlatfromServicesCreateRequestProps>(explorePlatfromServicesCreateRequestTabs[tabIndex].component, {
              tabIndex,
              setTabIndex,
              data,
              setData,
              questions,
              referenceTableContents,
              original,
            })}
          </Box>
        </TabPanels>
      </Tabs>
    </VStack>
  );
};

export default ExplorePlatformServicesCreateRequest;
 