import {AddEmployeeOnboardingRequestModel, EmployeeOnboardingCreateRequestQAListFirstModel, EmployeeOnboardingCreateRequestQAListSecondModel } from "../../../app/services/dme/api/types";

export type EmployeeOnboardingCreateRequestProps = {
    data: AddEmployeeOnboardingRequestModel;
    setData: React.Dispatch<React.SetStateAction<AddEmployeeOnboardingRequestModel>>;
    questions: EmployeeOnboardingCreateRequestQAListFirstModel[];
    referenceTableContents: EmployeeOnboardingCreateRequestQAListSecondModel[];
  };
  
export const initialValuesEmployeeOnboardingCreateRequest: AddEmployeeOnboardingRequestModel = {
    first_name: '',
    last_name: '',
    personal_email_address: '',
    start_date: '',
    end_date: '',
    squad: '',
    specialty: '',
    geographical_location: '',
    additional_notes: '',
    virtual_machine_flag: false,
    requested_by_ref_user_id: 0,
    optional_public_ip_flag: false,
    contact_number: '',
    shipping_address_line_1: '',
    shipping_address_line_2: '',
    shipping_address_city: '',
    shipping_address_state: '',
    shipping_address_zip: '',
    shipping_address_country: '',
    employee_type: '',
    answers: [],
  };