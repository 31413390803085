import {
    Box,
    Divider,
    FormControl,
    Text,
    FormErrorMessage,
    FormLabel,
    Heading,
    Input,
    VStack,
    Flex,
    Textarea,
    ButtonGroup,
    Button,
    Alert,
    AlertIcon,
    Select,
    HStack,
    Checkbox,
    Grid,
  } from '@chakra-ui/react';
  import { useFormik } from 'formik';
  import * as Yup from 'yup';
  import { AddEmployeeOnboardingRequestModel, EmployeeOnboardingCreateRequestQAListFirstModel, EmployeeOnboardingCreateRequestQAListSecondModel, QuestionTypeModel } from '../../../app/services/dme/api/types';
  import dayjs from 'dayjs';
  import CustomDatePicker from '../../../components/CustomDatePicker';
  import { useState, useEffect, ChangeEvent } from 'react';
import { useAddEmployeeOnboardingRequestMutation, useGetEmployeeOffboardingRequestCreationQaListQuery } from '../../../app/services/dme/api/employeeOnboardingRequest';
import { useGetRefSpecialtyListQuery } from '../../../app/services/dme/api/refSpecialty';
import { useGetRefSquadListQuery } from '../../../app/services/dme/api/refSquad';
import { initialValuesEmployeeOnboardingCreateRequest } from './utils';
import { useGetUserListByRoleNameQuery } from '../../../app/services/dme/api/user';
import { useGetRefEmploymentTypeListQuery } from '../../../app/services/dme/api/refEmploymentType';
import { useAppSelector } from '../../../app/state/hooks';
  
  const _requestDetailsMaxLength = 2000;
  
  const FormSchema = Yup.object().shape({
    first_name: Yup.string().max(100).required('First Name is required'),
    last_name: Yup.string().max(100).required('Last Name is required'),
    personal_email_address: Yup.string().max(200).required('Personal Email Address is required'),
    contact_number: Yup.string().max(100).matches(/^[\d\s+\-()]*$/, 'Contact Number contains invalid character').required('Contact Number is required'),
    requested_by_ref_user_id: Yup.number().required('Hiring Manager is required'),
    squad: Yup.string().label('Squad').max(100).optional(),
    specialty: Yup.string().label('Specialty').max(100).optional(),
    geographical_location: Yup.string().max(100).required('Geographical Location is required'),
    virtual_machine_flag: Yup.boolean().label('Virtual Machine').optional(),
    additional_notes: Yup.string().label('Additional Notes').max(2000).optional(),
    shipping_address_line_1: Yup.string().max(100).required('Address Line 1 is required'),
    shipping_address_line_2: Yup.string().label('Address Line 2').max(100).optional(),
    shipping_address_city: Yup.string().max(50).required('City is required'),
    shipping_address_state: Yup.string().label('State').max(50).optional(),
    shipping_address_country: Yup.string().max(50).required('Country is required'),
    shipping_address_zip: Yup.string().max(15).required('ZIP Code is required'),
    employee_type: Yup.string().required('Employee Type is required'),
    start_date: Yup.date()
      .required('Start Date is required'),
    end_date: Yup.date()
      .nullable()
      .label('End Date')
      .when('employee_type', {
        is: 'Contractor',
        then: Yup.date()
          .required('End Date is required')
          .test(
            'end_date-after-start_date',
            'End Date must be the same as or after the Start Date',
            function (end_date){
              const  {start_date} = this.parent;
              return !end_date || !start_date || end_date >= start_date;
            }

          ),
        otherwise: Yup.date().nullable(),
      }),
  });

  const CreateEmployeeOnboardingRequest = () => {
    const [data, setData] = useState<AddEmployeeOnboardingRequestModel>(initialValuesEmployeeOnboardingCreateRequest);
    const [questions, setQuestions] = useState<EmployeeOnboardingCreateRequestQAListFirstModel[]>([]);
    const [referenceTableContents, setReferenceTableContents] = useState<EmployeeOnboardingCreateRequestQAListSecondModel[]>([]);
    const { data: qaListDetails, isLoading, isFetching } = useGetEmployeeOffboardingRequestCreationQaListQuery();
    const [postAsync, postDetail] = useAddEmployeeOnboardingRequestMutation();
    const specialtyDetails = useGetRefSpecialtyListQuery();
    const squadDetails = useGetRefSquadListQuery();
    const employmentTypeDetails = useGetRefEmploymentTypeListQuery();
    const hiringManagerDetails = useGetUserListByRoleNameQuery('Employee Onboarding Manager');
    const [StartDate, setStartDate] = useState<Date | undefined>();
    const [EndDate, setEndDate] = useState<Date | undefined>();
    const [alertMessage, setAlertMessage] = useState('');
    const [isContractor, setIsContractor] = useState(false);
    const [isSubmitClicked, setIsSubmitClicked] = useState(false);
    const [isVMRequested, setIsVMRequested] = useState(false);

    //get user info
    const { logonUser } = useAppSelector(s => s.user);
    const userId = logonUser?.ref_user_id ?? 0;
    data.requested_by_ref_user_id = userId;
  
    useEffect(() => {
      if (!qaListDetails?.first_dataset_model || qaListDetails?.first_dataset_model.length <= 0) return;
  
      const mapQuestionType = (type: string): QuestionTypeModel => {
          switch (type) {
            case 'Dropdown':
              return 'Dropdown';
            case 'Extended Open Text Box':
              return 'Extended Open Text Box';
            case 'Yes/No Questions':
              return 'Yes/No Questions';
            case 'Checkboxes':
              return 'Checkboxes';
            default:
              return 'Reference Table';
          }
      };
  
      const sortedQuestions = qaListDetails.first_dataset_model
        .map(question => ({
          ...question,
          question_type: mapQuestionType(question.question_type),
        }))
        .sort((a, b) => a.question_order - b.question_order);
  
      setQuestions(sortedQuestions);
      setData(d => ({
        ...d,
        answers: sortedQuestions.map(m => ({
          eo_ref_question_id: m.eo_ref_question_id,
        })),
      }));
  
      const referenceTableContents = qaListDetails.second_dataset_model;
  
      setReferenceTableContents(referenceTableContents);
  
    }, [qaListDetails?.first_dataset_model, qaListDetails?.second_dataset_model]);

    const {
      handleSubmit,
      errors,
      touched,
      handleChange,
      values,
      setValues,
      resetForm,
      setFieldValue,
      isSubmitting,
    } = useFormik<AddEmployeeOnboardingRequestModel>({
      enableReinitialize: true,
      validationSchema: FormSchema,
      initialValues: data,
      onSubmit: async (values, form) => {
        //Remove empty entry
        const cleanedAnswers = values.answers.filter(
          (answer) => (answer.reference_table_name_id !== null && answer.reference_table_name_id !== undefined) ||
                     (answer.user_answer_option !== null && answer.user_answer_option !== undefined) ||
                     (answer.user_answer_text_box !== null && answer.user_answer_text_box !== undefined)
        );
      
        // Create a new values object with the filtered answers
        const filteredValues = {
          ...values, // Keep the rest of the values
          answers: cleanedAnswers, // Replace answers with the filtered version
        };

        await postAsync(filteredValues);  // async POST request
      },
    });

    //Process after postAsync
    useEffect(() => {
      const { isSuccess, isError, isLoading } = postDetail;
  
      if (isSuccess) {
        clearForm();
        setAlertMessage('Your request has been successfully submitted. The page will refresh shortly.');
        setTimeout(() => {
          setAlertMessage('');
          window.location.reload();
        }, 8000);
      } else if (isError) {
        setAlertMessage('There was an error processing your request, please try again later.');
      } else {
        setAlertMessage('');
      }
  
      if (isLoading) {
        setAlertMessage('');
      }
    }, [postDetail]);
    
    const clearForm = () => {
      resetForm();
      setAlertMessage('');
      setStartDate(undefined);
      setEndDate(undefined);
      setIsContractor(false);
      setData(initialValuesEmployeeOnboardingCreateRequest);
      setIsSubmitClicked(false);
    };

    const handleAnswerChange = (index: number, field: string, value: any, isCheckbox = false) => {
      
      let tmp = values.answers.map(r => ({ ...r })); // Create a shallow copy of the answers array
    
      if (isCheckbox) {
        // For checkboxes, add a new entry or remove the existing one
        const existingIndex = tmp.findIndex(
          answer =>
            answer.eo_ref_question_id === 6 &&
            answer.reference_table_name_id === value
        );
    
        if (existingIndex > -1) {
          // Remove the entry if it exists (uncheck)
          tmp.splice(existingIndex, 1);
        } else {
          // Add a new entry for the selected checkbox
          tmp.push({
            eo_ref_question_id: 6, //ID of Software License
            reference_table_name_id: value,
            user_answer_option: undefined,
            user_answer_text_box: undefined,
          });
        }
      } else {
        // For other fields like dropdowns and textboxes
        tmp[index] = { ...tmp[index], [field]: value };
      }
    
      setValues({ ...values, answers: tmp });
    };

    return (
      <VStack>
        <Heading size="md">Create Employee Onboarding Request</Heading>
        <Divider />
        
        <form onSubmit={handleSubmit}>
          <VStack spacing="5" w="container.sm">
          {/* <VStack spacing="5" pt={5} w="container.sm"> */}
            <FormControl isInvalid={!!errors.first_name && touched.first_name} display="flex" >
              <FormLabel minW="200px" textAlign= "right" htmlFor="first_name">
                First Name*
              </FormLabel>
              <Box w="full">
                <Input
                  size="sm"
                  id="first_name"
                  name="first_name"
                  onChange={handleChange}
                  onBlur={e =>
                    setValues({
                      ...values,
                      first_name: e.target.value.trim(),
                    })
                  }
                  value={values.first_name}
                  maxLength={100}
                />
                <FormErrorMessage>{errors.first_name}</FormErrorMessage>
              </Box>
            </FormControl>
            
            <FormControl isInvalid={!!errors.last_name && touched.last_name} display="flex" >
              <FormLabel minW="200px" textAlign= "right" htmlFor="last_name">
                Last Name*
              </FormLabel>
              <Box w="full">
                <Input
                  size="sm"
                  id="last_name"
                  name="last_name"
                  onChange={handleChange}
                  onBlur={e =>
                    setValues({
                      ...values,
                      last_name: e.target.value.trim(),
                    })
                  }
                  value={values.last_name}
                  maxLength={100}
                />
                <FormErrorMessage>{errors.last_name}</FormErrorMessage>
              </Box>
            </FormControl>

            <FormControl isInvalid={!!errors.personal_email_address && touched.personal_email_address} display="flex" >
              <FormLabel minW="200px" textAlign= "right" htmlFor="personal_email_address">
                Personal Email Address*
              </FormLabel>
              <Box w="full">
                <Input
                  size="sm"
                  id="personal_email_address"
                  name="personal_email_address"
                  onChange={handleChange}
                  onBlur={e =>
                    setValues({
                      ...values,
                      personal_email_address: e.target.value.trim(),
                    })
                  }
                  value={values.personal_email_address}
                  maxLength={100}
                />
                <FormErrorMessage>{errors.personal_email_address}</FormErrorMessage>
              </Box>
            </FormControl>
          <HStack>
            <FormControl isInvalid={!!errors.contact_number && touched.contact_number} display="flex" >
              <FormLabel minW="200px" textAlign= "right" htmlFor="contact_number">
                Contact Number*
              </FormLabel>
              <Box w="full">
                <Input
                  size="sm"
                  id="contact_number"
                  name="contact_number"
                  // placeholder='(123)456-7890 or +63 (123)456-7890'
                  onChange={handleChange}
                  onBlur={e =>
                    setValues({
                      ...values,
                      contact_number: e.target.value.trim(),
                    })
                  }
                  value={values.contact_number}
                  maxLength={100}
                />
                <FormErrorMessage>{errors.contact_number}</FormErrorMessage>
              </Box>
            </FormControl>
                <Text color="gray.400"> Format: (123)456-7890 or +63 (123)456-7890</Text>
            </HStack>

            <FormControl isInvalid={!!errors.requested_by_ref_user_id && !!touched.requested_by_ref_user_id} display="flex">
              <FormLabel minW="200px" textAlign= "right" htmlFor="requested_by_ref_user_id">Hiring Manager*</FormLabel>
              <Box w="100%">
                <Select
                  size="sm"
                  id="requested_by_ref_user_id"
                  name="requested_by_ref_user_id"
                  width= '200px'
                  isDisabled={hiringManagerDetails.isLoading || hiringManagerDetails.isFetching}
                  value={values.requested_by_ref_user_id}  // Ensures the value is a string
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    const selectedRequestecByRefUserId = parseInt(e.target.value, 10);
                    const selectedRequestecByRefUserIdObj = hiringManagerDetails.data?.find(m => m.ref_user_id === selectedRequestecByRefUserId);
                    setValues({
                      ...values,
                      requested_by_ref_user_id: selectedRequestecByRefUserIdObj?.ref_user_id || 0,
                    });
                  }}
                >
                  {(hiringManagerDetails.data ?? []).map(m => (
                    <option key={m.ref_user_id} value={m.ref_user_id}>
                      {m.first_name} {m.last_name}
                    </option>
                  ))}
                </Select>
                <FormErrorMessage>{errors.requested_by_ref_user_id}</FormErrorMessage>
              </Box>
            </FormControl>
          
            <FormControl isInvalid={!!errors.squad && !!touched.squad} display="flex">
              <FormLabel minW="200px" textAlign= "right">Squad</FormLabel>
              <Box w="100%">
                <Select
                  size="sm"
                  id="squad"
                  name="squad"
                  placeholder="Select option"
                  width= '200px'
                  isDisabled={squadDetails.isLoading || squadDetails.isFetching}
                  value={values.squad || ''}  // Ensures the value is a string
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    const selectedSquad = e.target.value;
                    const selectedSquadObj = squadDetails.data?.find(m => m.squad_name === selectedSquad);
                    setValues({
                      ...values,
                      squad: selectedSquadObj?.squad_name || '',
                    });
                  }}
                >
                  {(squadDetails.data ?? []).map(m => (
                    <option key={m.squad_name} value={m.squad_name}>
                      {m.squad_name}
                    </option>
                  ))}
                </Select>
                <FormErrorMessage>{errors.squad}</FormErrorMessage>
              </Box>
            </FormControl>

            <FormControl isInvalid={!!errors.specialty && !!touched.specialty} display="flex">
              <FormLabel minW="200px" textAlign= "right">Specialty</FormLabel>
              <Box w="100%">
                <Select
                  size="sm"
                  id="specialty"
                  name="specialty"
                  placeholder="Select option"
                  width= '200px'
                  isDisabled={specialtyDetails.isLoading || specialtyDetails.isFetching}
                  value={values.specialty || ''}  // Ensures the value is a string
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    const selectedSpecialty = e.target.value;
                    const selectedSpecialtyObj = specialtyDetails.data?.find(m => m.specialty_name === selectedSpecialty);
                    setValues({
                      ...values,
                      specialty: selectedSpecialtyObj?.specialty_name || '',
                    });
                  }}
                >
                  {(specialtyDetails.data ?? []).map(m => (
                    <option key={m.specialty_name} value={m.specialty_name}>
                      {m.specialty_name}
                    </option>
                  ))}
                </Select>
                <FormErrorMessage>{errors.specialty}</FormErrorMessage>
              </Box>
            </FormControl>

            <FormControl isInvalid={!!errors.geographical_location && !!touched.geographical_location} display="flex" >
              <FormLabel minW="200px" textAlign= "right">Geographical Location*</FormLabel>
              <Box w="100%">
                <Select
                  size="sm"
                  id="geographical_location"
                  name="geographical_location"
                  placeholder="Select option"
                  width="200px"
                  value={values.geographical_location || ''} // Ensures the value is a string
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    const selectedLocation = e.target.value;
                    setValues({
                      ...values,
                      geographical_location: selectedLocation,
                    });
                  }}
                >
                  <option value="PH">PH</option>
                  <option value="USA">USA</option>
                </Select>
                <FormErrorMessage>{errors.geographical_location}</FormErrorMessage>
              </Box>
            </FormControl>

            <FormControl isInvalid={!!errors.shipping_address_line_1 && touched.shipping_address_line_1} display="flex" >
              <FormLabel minW="200px" textAlign= "right" htmlFor="shipping_address_line_1">
                Address Line 1*
              </FormLabel>
              <Box w="full">
                <Input
                  size="sm"
                  id="shipping_address_line_1"
                  name="shipping_address_line_1"
                  onChange={handleChange}
                  onBlur={e =>
                    setValues({
                      ...values,
                      shipping_address_line_1: e.target.value.trim(),
                    })
                  }
                  value={values.shipping_address_line_1}
                  maxLength={100}
                />
                <FormErrorMessage>{errors.shipping_address_line_1}</FormErrorMessage>
              </Box>
            </FormControl>

            <FormControl isInvalid={!!errors.shipping_address_line_2 && touched.shipping_address_line_2} display="flex">
              <FormLabel minW="200px" textAlign= "right" htmlFor="shipping_address_line_2">
                Address Line 2
              </FormLabel>
              <Box w="full">
                <Input
                  size="sm"
                  id="shipping_address_line_2"
                  name="shipping_address_line_2"
                  onChange={handleChange}
                  onBlur={e =>
                    setValues({
                      ...values,
                      shipping_address_line_2: e.target.value.trim(),
                    })
                  }
                  value={values.shipping_address_line_2}
                  maxLength={100}
                />
                <FormErrorMessage>{errors.shipping_address_line_2}</FormErrorMessage>
              </Box>
            </FormControl>

            <FormControl isInvalid={!!errors.shipping_address_city && touched.shipping_address_city} display="flex" >
              <FormLabel minW="200px" textAlign= "right"  htmlFor="shipping_address_city">
                City*
              </FormLabel>
              <Box w="full">
                <Input
                  size="sm"
                  id="shipping_address_city"
                  name="shipping_address_city"
                  onChange={handleChange}
                  onBlur={e =>
                    setValues({
                      ...values,
                      shipping_address_city: e.target.value.trim(),
                    })
                  }
                  value={values.shipping_address_city}
                  maxLength={100}
                  width="200px"
                />
                <FormErrorMessage>{errors.shipping_address_city}</FormErrorMessage>
              </Box>
            </FormControl>

            <FormControl isInvalid={!!errors.shipping_address_state && touched.shipping_address_state} display="flex">
              <FormLabel minW="200px" textAlign= "right" htmlFor="shipping_address_state">
                State
              </FormLabel>
              <Box w="full">
                <Input
                  size="sm"
                  id="shipping_address_state"
                  name="shipping_address_state"
                  onChange={handleChange}
                  onBlur={e =>
                    setValues({
                      ...values,
                      shipping_address_state: e.target.value.trim(),
                    })
                  }
                  value={values.shipping_address_state}
                  maxLength={100}
                  width="200px"
                />
                <FormErrorMessage>{errors.shipping_address_state}</FormErrorMessage>
              </Box>
            </FormControl>

            <FormControl isInvalid={!!errors.shipping_address_zip && touched.shipping_address_zip} display="flex" >
              <FormLabel minW="200px" textAlign= "right" htmlFor="shipping_address_zip">
                ZIP Code*
              </FormLabel>
              <Box w="full">
                <Input
                  size="sm"
                  id="shipping_address_zip"
                  name="shipping_address_zip"
                  onChange={handleChange}
                  onBlur={e =>
                    setValues({
                      ...values,
                      shipping_address_zip: e.target.value.trim(),
                    })
                  }
                  value={values.shipping_address_zip}
                  maxLength={100}
                  width="100px"
                />
                <FormErrorMessage>{errors.shipping_address_zip}</FormErrorMessage>
              </Box>
            </FormControl>

            <FormControl isInvalid={!!errors.shipping_address_country && touched.shipping_address_country} display="flex" >
              <FormLabel minW="200px" textAlign= "right" htmlFor="shipping_address_country">
                Country*
              </FormLabel>
              <Box w="full">
                <Input
                  size="sm"
                  id="shipping_address_country"
                  name="shipping_address_country"
                  onChange={handleChange}
                  onBlur={e =>
                    setValues({
                      ...values,
                      shipping_address_country: e.target.value.trim(),
                    })
                  }
                  value={values.shipping_address_country}
                  maxLength={100}
                  width="200px"
                />
                <FormErrorMessage>{errors.shipping_address_country}</FormErrorMessage>
              </Box>
            </FormControl>

            <FormControl isInvalid={!!errors.employee_type && !!touched.employee_type} display="flex">
              <FormLabel minW="200px" textAlign= "right">Employee Type*</FormLabel>
              <Box w="100%">
                <Select
                  size="sm"
                  id="employee_type"
                  name="employee_type"
                  placeholder="Select option"
                  width= '200px'
                  isDisabled={employmentTypeDetails.isLoading || employmentTypeDetails.isFetching}
                  value={values.employee_type || ''}  // Ensures the value is a string
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    const selectedEmploymentType = e.target.value;
                    const selectedEmploymentTypeObj = employmentTypeDetails.data?.find(m => m.employment_name === selectedEmploymentType);
                    setValues({
                      ...values,
                      employee_type: selectedEmploymentTypeObj?.employment_name || '',
                    });
                    if(selectedEmploymentType === 'Contractor'){
                      setIsContractor(true);
                    } else{
                      setIsContractor(false);
                      setEndDate(undefined);
                      setFieldValue("end_date", null);
                    }
                  }}
                >
                  {(employmentTypeDetails.data ?? []).map(m => (
                    <option key={m.employment_name} value={m.employment_name}>
                      {m.employment_name}
                    </option>
                  ))}
                </Select>
                <FormErrorMessage>{errors.employee_type}</FormErrorMessage>
              </Box>
            </FormControl>

          {/* Start Date Picker */}
          <FormControl isInvalid={!!errors.start_date && touched.start_date} >
              <Flex>
                <FormLabel minW="200px" textAlign= "right" htmlFor="start_date">
                  Start Date*
                </FormLabel>
                <VStack w="200px">
                  <CustomDatePicker
                    id="start_date"
                    name="start_date"
                    date={StartDate}
                    onDateChange={(date: Date) => {
                      setValues({
                        ...values,
                        start_date: dayjs(date).format('YYYY-MM-DD'),
                      });
                      setStartDate(date);
                    }}
                    propsConfigs={{
                      dateNavBtnProps: {
                        colorScheme: 'brand.main.default',
                        variant: 'outline',
                      },
                      dayOfMonthBtnProps: {
                        defaultBtnProps: {
                          _hover: {
                            background: 'brand.main.default',
                            color: 'white',
                          },
                        },
                        selectedBtnProps: {
                          background: 'brand.main.default',
                          color: 'white',
                        },
                        todayBtnProps: {
                          background: 'gray.400',
                        },
                      },
                      inputProps: {
                        placeholder: 'YYYY-MM-DD',
                        size: 'sm',
                        value: values.start_date,
                        onChange: (e: ChangeEvent<HTMLInputElement>) => {
                          setValues({
                            ...values,
                            start_date: e.target.value,
                          });
                          if (dayjs(e.target.value)?.isValid()) {
                            setStartDate(dayjs(e.target.value).toDate());
                          }
                        },
                      },
                    }}
                  />
                  <FormErrorMessage>{errors.start_date}</FormErrorMessage>
                </VStack>
              </Flex>
            </FormControl>

            { isContractor && (
            <FormControl isInvalid={!!errors.end_date && touched.end_date}>
              <Flex>
                <FormLabel minW="200px" textAlign= "right" htmlFor="end_date">
                  End Date*
                </FormLabel>
                <VStack w="200px">
                  <CustomDatePicker
                    id="end_date"
                    name="end_date"
                    date={EndDate}
                    onDateChange={(date: Date) => {
                      setValues({
                        ...values,
                        end_date: dayjs(date).format('YYYY-MM-DD'),
                      });
                      setEndDate(date);
                    }}
                    propsConfigs={{
                      dateNavBtnProps: {
                        colorScheme: 'brand.main.default',
                        variant: 'outline',
                      },
                      dayOfMonthBtnProps: {
                        defaultBtnProps: {
                          _hover: {
                            background: 'brand.main.default',
                            color: 'white',
                          },
                        },
                        selectedBtnProps: {
                          background: 'brand.main.default',
                          color: 'white',
                        },
                        todayBtnProps: {
                          background: 'gray.400',
                        },
                      },
                      inputProps: {
                        placeholder: 'YYYY-MM-DD',
                        size: 'sm',
                        value: values.end_date,
                        onChange: (e: ChangeEvent<HTMLInputElement>) => {
                          setValues({
                            ...values,
                            end_date: e.target.value,
                          });
                          if (dayjs(e.target.value)?.isValid()) {
                            setEndDate(dayjs(e.target.value).toDate());
                          }
                        },
                      },
                    }}
                  />
                  <FormErrorMessage>{errors.end_date}</FormErrorMessage>
                </VStack>
              </Flex>
            </FormControl>
            )}

            <FormControl isInvalid={false} display="flex">
              <FormLabel minW="200px" textAlign= "right">Virtual Machine (VM)</FormLabel>
                <Box minW="100px">
                  <Select
                        size="sm"
                        id="virtual_machine_flag"
                        name="virtual_machine_flag"
                        value={values.virtual_machine_flag ? 'true' : 'false'}
                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                          const selectedValue = e.target.value === 'true';
                          setValues({
                            ...values,
                            virtual_machine_flag: selectedValue,
                          });
                          if(selectedValue === true){
                            setIsVMRequested(true);
                          } else{
                            setIsVMRequested(false);
                          }
                        }}
                  >
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </Select>
                </Box>
              <FormErrorMessage></FormErrorMessage>
            </FormControl>
            
            { isVMRequested && (
            <FormControl isInvalid={false} display="flex">
              <FormLabel minW="200px" textAlign= "right">VM: Optional Public IP</FormLabel>
                <Box minW="100px">
                  <Select
                        size="sm"
                        id="optional_public_ip_flag"
                        name="optional_public_ip_flag"
                        value={values.optional_public_ip_flag ? 'true' : 'false'}
                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                          const selectedValue = e.target.value === 'true';
                          setValues({
                            ...values,
                            optional_public_ip_flag: selectedValue,
                          })
                        }}
                  >
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </Select>
                </Box>
              <FormErrorMessage></FormErrorMessage>
            </FormControl>
            )}

          {questions.map((question, i) => {
            // Define conditional rendering logic
            const isVMQuestion =
              isVMRequested &&
              question.question_instruction_text.toLowerCase().includes('vm');
            const isNotContractorHardwareQuestion =
              !isContractor &&
              question.question_instruction_text.toLowerCase().includes('hardware');
            const isLicenseQuestion =
              question.question_instruction_text.toLowerCase().includes('license');

            return (
              (isVMQuestion || isNotContractorHardwareQuestion || isLicenseQuestion) && (
                <Box key={question.eo_ref_question_id}>
                  {isVMQuestion && (
                    <FormControl
                      isInvalid={
                        !!errors.answers &&
                        !!(errors.answers[i] as any)?.eo_ref_question_id &&
                        !!touched.answers &&
                        !!touched.answers[i]?.eo_ref_question_id
                      }
                      display="flex"
                    >
                      <FormLabel minW="200px" textAlign="right">
                        {question.question_instruction_text}
                      </FormLabel>
                      {question.question_type === 'Dropdown' &&
                        question.use_reference_table_flag && (
                          <Select
                            size="sm"
                            width="200px"
                            placeholder="Select option"
                            value={values.answers[i]?.reference_table_name_id || ''}
                            onChange={(e) =>
                              handleAnswerChange(
                                i,
                                'reference_table_name_id',
                                parseInt(e.target.value)
                              )
                            }
                          >
                            {referenceTableContents
                              .filter(
                                (ref) => ref.eo_ref_question_id === question.eo_ref_question_id
                              )
                              .map((ref) => (
                                <option key={ref.pk_id} value={ref.pk_id}>
                                  {ref.pk_name}
                                </option>
                              ))}
                          </Select>
                        )}
                      <FormErrorMessage>
                        {!!errors.answers && (errors.answers[i] as any)?.eo_ref_question_id}
                      </FormErrorMessage>
                    </FormControl>
                  )}

                  {isNotContractorHardwareQuestion && (
                    <FormControl
                      isInvalid={
                        !!errors.answers &&
                        !!(errors.answers[i] as any)?.eo_ref_question_id &&
                        !!touched.answers &&
                        !!touched.answers[i]?.eo_ref_question_id
                      }
                      display="flex"
                    >
                      <FormLabel minW="200px" textAlign="right">
                        {question.question_instruction_text}
                      </FormLabel>
                      {question.question_type === 'Dropdown' &&
                        question.use_reference_table_flag && (
                          <Select
                            size="sm"
                            width="200px"
                            placeholder="Select option"
                            value={values.answers[i]?.reference_table_name_id || ''}
                            onChange={(e) =>
                              handleAnswerChange(
                                i,
                                'reference_table_name_id',
                                parseInt(e.target.value)
                              )
                            }
                          >
                            {referenceTableContents
                              .filter(
                                (ref) => ref.eo_ref_question_id === question.eo_ref_question_id
                              )
                              .map((ref) => (
                                <option key={ref.pk_id} value={ref.pk_id}>
                                  {ref.pk_name}
                                </option>
                              ))}
                          </Select>
                        )}
                      <FormErrorMessage>
                        {!!errors.answers && (errors.answers[i] as any)?.eo_ref_question_id}
                      </FormErrorMessage>
                    </FormControl>
                  )}

                  {isLicenseQuestion && (
                    <FormControl
                      isInvalid={
                        !!errors.answers &&
                        !!(errors.answers[i] as any)?.eo_ref_question_id &&
                        !!touched.answers &&
                        !!touched.answers[i]?.eo_ref_question_id
                      }
                      display="flex"
                    >
                      <FormLabel minW="200px" textAlign="right">
                        {question.question_instruction_text}
                      </FormLabel>
                      {/* Create a Flex container with two rows of checkboxes */}
                      <Grid gap={4}> {/* You can adjust the `templateColumns` to your needs */}
                        {referenceTableContents
                          .filter(
                            (ref) => ref.eo_ref_question_id === question.eo_ref_question_id
                          )
                          .map((ref) => (
                            <Checkbox
                              key={ref.pk_id}
                              value={ref.pk_id}
                              isChecked={values.answers.some(
                                (answer) =>
                                  answer.eo_ref_question_id === question.eo_ref_question_id &&
                                  answer.reference_table_name_id === ref.pk_id
                              )}
                              onChange={(e) =>
                                handleAnswerChange(
                                  i,
                                  'reference_table_name_id',
                                  parseInt(e.target.value),
                                  true // Checkbox flag
                                )
                              }
                              sx={{ marginLeft: '20px' }}
                            >
                              {ref.pk_name}
                            </Checkbox>
                          ))}
                        </Grid>
                      <FormErrorMessage>
                        {!!errors.answers && (errors.answers[i] as any)?.eo_ref_question_id}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Box>
              )
            );
          })}

          <FormControl isInvalid={!!errors.additional_notes && touched.additional_notes} alignItems="start">
              <Box textAlign="right">
                {(values.additional_notes?.length || 0)> 0 && (
                  <Text as="small">{`${values.additional_notes?.length}/${2000}`}</Text>
                )}
              </Box>
              <Flex>
                <FormLabel minW="200px" textAlign= "right" htmlFor="additional_notes">
                  Additional Notes
                </FormLabel>
                <Box width="full">
                  <Textarea
                    size="sm"
                    id="additional_notes"
                    name="additional_notes"
                    onChange={handleChange}
                    onBlur={e =>
                      setValues({
                        ...values,
                        additional_notes: e.target.value.trim(),
                      })
                    }
                    value={values.additional_notes}
                    maxLength={_requestDetailsMaxLength}
                  />
                  <FormErrorMessage>{errors.additional_notes}</FormErrorMessage>
                </Box>
              </Flex>
          </FormControl>
          
            {alertMessage && (
              <Alert status={postDetail.isSuccess ? 'success' : 'error'}>
                <AlertIcon />
                {alertMessage}
              </Alert>
            )}

             {/* General form error message */}
             {Object.keys(errors).length > 0 && !isSubmitting && isSubmitClicked && (
              <Alert status= 'error'>
                <AlertIcon />
                Please fill in all required fields and fix any errors.
              </Alert>
            )}
  
            <ButtonGroup>
              <Button isDisabled={postDetail.isLoading} variant="outline" onClick={() => clearForm()} ml="auto">
                Cancel
              </Button>
              <Button isLoading={postDetail.isLoading || isSubmitting } variant="solid" colorScheme="brand.main" type="submit" onClick={() => setIsSubmitClicked(true)}>
                Submit
              </Button>
            </ButtonGroup>

          </VStack>
        </form>
      </VStack>
    );

  };
  
  export default CreateEmployeeOnboardingRequest;
  