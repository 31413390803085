import { WarningIcon } from '@chakra-ui/icons';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  Heading,
  HStack,
  Icon,
  Tab,
  TabList,
  TabPanels,
  Tabs,
  VStack,
} from '@chakra-ui/react';
import { createElement, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { AppAccess } from '../../../../app/constants/appAccesses';
import { addBreadcrumb } from '../../../../app/helpers/navigationHelpers';
import AppAuth from '../../../../features/AppAuth';
import AppAccessByRolesSelectedAppAccess from './AppAccess';
import AppAccessByRolesSelectedProvider, {
  AppAccessByRolesSelectedFormData,
  useAppAccessByRolesSelectedContext,
} from './AppAccessByRolesSelectedContext';
import AppAccessByRolesSelectedDataProducts from './DataProducts';
import AppAccessByRolesSelectedDetails from './Details';
import AppAccessByRolesSelectedDevelopmentEnvironment from './DevelopmentEnvironment';
import AppAccessByRolesSelectedReportPortfolios from './ReportPortfolios';
import AppAccessByRolesSelectedUsers from './Users';
import AppAccessByRolesSelectedServiceAccounts from './ServiceAccounts';

const tabs: {
  label: string;
  path: string;
  component: ({ data }: any) => JSX.Element;
  model?: keyof AppAccessByRolesSelectedFormData;
}[] = [
  {
    label: 'Details',
    path: 'details',
    component: AppAccessByRolesSelectedDetails,
    model: 'details',
  },
  {
    label: 'App Access',
    path: 'app-access',
    component: AppAccessByRolesSelectedAppAccess,
    model: 'appAccess',
  },
  {
    label: 'Development Environment',
    path: 'development-environment',
    component: AppAccessByRolesSelectedDevelopmentEnvironment,
    model: 'devEnv',
  },
  {
    label: 'Data Products',
    path: 'data-products',
    component: AppAccessByRolesSelectedDataProducts,
  },
  {
    label: 'Report Portfolios',
    path: 'report-portfolios',
    component: AppAccessByRolesSelectedReportPortfolios,
  },
  {
    label: 'Users',
    path: 'users',
    component: AppAccessByRolesSelectedUsers,
  },
  {
    label: 'Service Accounts',
    path: 'service_accounts',
    component: AppAccessByRolesSelectedServiceAccounts,
  },
];

const AppAccessByRolesSelected = () => {
  return (
    <AppAccessByRolesSelectedProvider>
      <AppAccessByRolesSelectedChild />
    </AppAccessByRolesSelectedProvider>
  );
};

const AppAccessByRolesSelectedChild = () => {
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const { setRoleId, detailsDetail, isEdit, setIsEdit, handleSave, form, headerError, putDetail } =
    useAppAccessByRolesSelectedContext();

  const [tabIndex, setTabIndex] = useState<number | undefined>();

  const handleTabsChange = (index: number) => {
    setTabIndex(index);
  };

  useEffect(() => {
    if (tabIndex !== undefined && params.selectedTab !== tabs[tabIndex].path) {
      navigate('./../' + tabs[tabIndex].path);
    }
  }, [tabIndex]);

  useEffect(() => {
    const paramTab = params.selectedTab;

    if (paramTab !== undefined) {
      const pathIndex = tabs.findIndex(f => f.path === paramTab);
      pathIndex !== tabIndex && setTabIndex(pathIndex);
    }
  }, [params]);

  useEffect(() => {
    const id = +(params?.id || 0);
    if (id) setRoleId(id);
  }, [params.id, setRoleId]);

  useEffect(() => {
    addBreadcrumb({ label: 'Selected', path: location.pathname });

    const isEdit = !!(location.state as { isEdit?: boolean })?.isEdit;
    if (isEdit) setIsEdit(isEdit);
  }, [location]);

  return (
    <VStack w="100%" spacing={5} p={1} pt={3}>
      <HStack h="10">
        <Heading size="md" noOfLines={1}>
          {detailsDetail.data && detailsDetail.data.role_name}
        </Heading>
        {detailsDetail.data && (
          <AppAuth requiredAppAccess={AppAccess.AppAccessByRolesWrite}>
            {isEdit ? (
              <HStack>
                <Button
                  variant="outline"
                  colorScheme="brand.main"
                  onClick={() => setIsEdit(false)}
                  isDisabled={putDetail.isLoading}
                >
                  Cancel
                </Button>
                <Button
                  variant="solid"
                  colorScheme="brand.main"
                  onClick={handleSave}
                  isDisabled={putDetail.isLoading}
                  isLoading={putDetail.isLoading}
                >
                  Save
                </Button>
              </HStack>
            ) : (
              <Button variant="solid" colorScheme="brand.main" onClick={() => setIsEdit(true)}>
                Edit
              </Button>
            )}
          </AppAuth>
        )}
        {headerError && (
          <Alert py={2} status="error">
            <AlertIcon />
            <AlertDescription>{headerError}</AlertDescription>
          </Alert>
        )}
      </HStack>
      {tabIndex !== undefined && (
        <Tabs index={tabIndex} onChange={handleTabsChange}>
          <TabList>
            {tabs.map((m, i) => (
              <Tab key={i}>
                {m.label}
                {m.model && form?.touched[m.model] && form?.errors[m.model] && (
                  <Icon color="brand.error" ml={2} as={WarningIcon} />
                )}
              </Tab>
            ))}
          </TabList>
          <TabPanels>
            <Box p={3}>{createElement(tabs[tabIndex].component, {})}</Box>
          </TabPanels>
        </Tabs>
      )}
    </VStack>
  );
};

export default AppAccessByRolesSelected;
