import CustomTable from '../../../../components/CustomTable';
import { useAppAccessByRolesSelectedContext } from './AppAccessByRolesSelectedContext';

const Header = [
  {
    Header: 'Service Account Name',
    accessor: 'service_account_name',
    styles: { whiteSpace: 'initial' },
  },
];

const AppAccessByRolesSelectedServiceAccounts = () => {
  const {
    serviceAccsDetail: { data, isLoading, isFetching },
  } = useAppAccessByRolesSelectedContext();

  return isLoading ? (
    <>Loading...</>
  ) : (
    <CustomTable
      variant="table"
      headers={Header}
      isLoading={isLoading}
      isFetching={isFetching}
      data={data || []}
      pageCount={0}
      pageSize={data ? data.length : 0}
      totalRecords={data ? data.length : 0}
      search=""
      onPageChange={index => {}}
      onPageSizeChange={size => {}}
      onPageSearch={search => {}}
      onSort={() => {}}
      hidePagination={true}
      tableSort={true}
      showNoRecords
      // onRowClick={onClickRow}
    />
  );
};

export default AppAccessByRolesSelectedServiceAccounts;
