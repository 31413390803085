import { dmeApi } from './index';
import { GroupClassificationLoreModel } from './types';

const baseUrl = '/group';

const extendedApi = dmeApi.injectEndpoints({
  endpoints: build => ({

    getFunctionalGroupsList: build.query<GroupClassificationLoreModel[], number>({
        query: grp_classification_id => baseUrl + `/classification/lore/grp_classification_id/${grp_classification_id}`,
      }),
  }),
  overrideExisting: false,
});

export const { useGetFunctionalGroupsListQuery } = extendedApi;
