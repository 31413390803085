import { Box, Text, Divider, Heading, HStack, VStack, Skeleton, Stack } from '@chakra-ui/react';
import CustomTable from '../../../components/CustomTable';
import { GroupClassificationLoreModel } from '../../../app/services/dme/api/types';
import { useGetFunctionalGroupsListQuery } from '../../../app/services/dme/api/group';

type Props = {};

const ThreeTwelveGroups = (props: Props) => {
  const grp_classification_id = 3; // 3/12 Groups id
  const { data, isLoading, isFetching } = useGetFunctionalGroupsListQuery(grp_classification_id);

  const Header = [
    {
      Header: 'Group Name',
      accessor: 'grp_lore_name',
      styles: {width: '170px', whiteSpace: 'initial', wordBreak: 'break-word' },
    },
    {
      Header: 'Description',
      accessor: 'grp_lore_desc',
      styles: { whiteSpace: 'initial' },
      Cell: ({ row: { original } }: { row: { original: GroupClassificationLoreModel } }) => {
        return (
          <Box maxW="100%">
             <Text title={original.grp_lore_desc} dangerouslySetInnerHTML={{ __html: original.grp_lore_desc }} />
          </Box>
        );
      },
    },
  ];

  if (isLoading || isFetching) return <div>Loading...</div>;

  return (
    <VStack>
      <HStack>
        <Heading size="md">3/12 Groups</Heading>
      </HStack>
      <Divider />
      <HStack>
        {isLoading ? (
          <Stack mt={1}>
            {[...Array(8)].map((m, i) => (
              <Skeleton key={i} height="18px" />
            ))}
          </Stack>
        ) : (
          <CustomTable
            variant="table"
            headers={Header}
            isLoading={isLoading}
            isFetching={isFetching}
            data={data || []}
            pageCount={0}
            pageSize={data ? data.length : 0}
            totalRecords={data ? data.length : 0}
            search=""
            onPageChange={() => {}}
            onPageSizeChange={() => {}}
            onPageSearch={() => {}}
            onSort={() => {}}
            hidePagination={true}
            tableSort={true}
          />
        )}
      </HStack>
    </VStack>
  );
};

export default ThreeTwelveGroups;
