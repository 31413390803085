import { Box, Text } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetDataProductSummaryQuery } from '../../../../app/services/dme/api/dataProduct';
import { DataProductSummaryModel } from '../../../../app/services/dme/api/types';
import CustomTable from '../../../../components/CustomTable';

type Props = {
  setTotalProduct: (count: number) => void;
};

const Summary = ({ setTotalProduct }: Props) => {
  const { data, isLoading, isFetching } = useGetDataProductSummaryQuery();
  const navigate = useNavigate();

  const SummaryHeader = [
    {
      Header: 'Squad',
      accessor: 'domain_name',
      styles: { whiteSpace: 'initial' },
      Cell: ({ row: { original } }: { row: { original: DataProductSummaryModel } }) => {
        return (
          <Box maxW="350px">
            <Text noOfLines={1} title={original.domain_name}>
              {original.domain_name}
            </Text>
          </Box>
        );
      },
    },
    {
      Header: 'Data Products',
      accessor: 'data_products',
      styles: { display: 'flex', justifyContent: 'center' },
    },
  ];

  const goToResultsPage = (domainName: string) =>
    navigate(`/data-products/explore-data-products/results?domain=${encodeURIComponent(domainName)}`);

  useEffect(() => {
    if (data && !isLoading) {
      setTotalProduct(data.length > 0 ? data?.map(m => m.data_products || 0)?.reduce((a, b) => a + b) : 0);
    }
  }, [data]);

  return (
    <Box p={3} borderWidth="1px" borderRadius="lg">
      <CustomTable
        variant="table"
        headers={SummaryHeader}
        isLoading={isLoading}
        isFetching={isFetching}
        data={data || []}
        pageCount={0}
        pageSize={data ? data.length : 0}
        totalRecords={data ? data.length : 0}
        search=""
        onPageChange={index => {}}
        onPageSizeChange={size => {}}
        onPageSearch={search => {}}
        onSort={() => {}}
        hidePagination={true}
        tableSort={true}
        onRowClick={row => goToResultsPage(row.domain_name)}
      />
    </Box>
  );
};

export default Summary;
