import { useParams } from 'react-router-dom';
import { useGetRefTagSelectedDataProductPayloadQuery } from '../../../../app/services/dme/api/refTags';
import CustomTable from '../../../../components/CustomTable';

const ExploreTagsSelectedDataProductPayloads = () => {
  const params = useParams();
  const refTagId: number = parseInt(params?.id || '') || 0;
  const { data, isLoading, isFetching } = useGetRefTagSelectedDataProductPayloadQuery(refTagId, {
    skip: refTagId === 0,
  });

  const Header = [
    {
      Header: 'Squad Name',
      accessor: 'domain_name',
      styles: { whiteSpace: 'initial' },
    },
    {
      Header: 'Data Product Name',
      accessor: 'data_product_name',
      styles: { whiteSpace: 'initial' },
    },
    {
      Header: 'Payload Name',
      accessor: 'data_element',
      styles: { whiteSpace: 'initial' },
    },
    {
      Header: 'Description',
      accessor: 'data_element_desc',
      styles: { whiteSpace: 'initial' },
    },
  ];

  return (
    <CustomTable
      variant="table"
      headers={Header}
      isLoading={isLoading}
      isFetching={isFetching}
      data={data || []}
      pageCount={0}
      pageSize={10}
      totalRecords={data ? data.length : 0}
      search=""
      onPageChange={index => {}}
      onPageSizeChange={size => {}}
      onPageSearch={search => {}}
      onSort={() => {}}
      hidePagination={true}
      tableSort={true}
      showNoRecords
    />
  );
};

export default ExploreTagsSelectedDataProductPayloads;
