import {
  Box,
  Button,
  Divider,
  HStack,
  Heading,
  Tab,
  TabList,
  TabPanels,
  Tabs,
  Tag,
  Text,
  Tooltip,
  VStack,
  useToast,
} from '@chakra-ui/react';
import { createElement, useEffect, useState } from 'react';
import { FaUnlockAlt } from 'react-icons/fa';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { DefaultInnerHtmlStyle } from '../../../../app/constants';
import { AppAccess } from '../../../../app/constants/appAccesses';
import { addBreadcrumb } from '../../../../app/helpers/navigationHelpers';
import { useGetDataProductSelectDetailByProductIdQuery } from '../../../../app/services/dme/api/dataProduct';
import { useAppSelector } from '../../../../app/state/hooks';
import AppAuth from '../../../../features/AppAuth';
import RequestAccessDialog from '../../RequestAccess';
import DataProductSelectedDetails from './Details';
import DataProductSelectedDiscussion from './Discussion';
import DataProductSelectedLineage from './Lineage';
import DataProductSelectedMode from './Mode';
import DataProductSelectedPayloadElementTag from './PayloadElementTag';
import DataProductSelectedRoles from './Roles';
import DataProductSelectedTags from './Tags';
import DataProductSelectedUsers from './Users';
import DataProductSelectedServiceAccounts from './ServiceAccounts';

type Props = {};
const tabs = [
  {
    label: 'Details',
    path: 'details',
    component: DataProductSelectedDetails,
  },
  {
    label: 'Payload and Element Tags',
    path: 'payload-element-tags',
    component: DataProductSelectedPayloadElementTag,
  },
  {
    label: 'Lineage',
    path: 'lineage',
    component: DataProductSelectedLineage,
  },
  {
    label: 'Mode',
    path: 'mode',
    component: DataProductSelectedMode,
  },
  {
    label: 'Tags',
    path: 'tags',
    component: DataProductSelectedTags,
  },
  {
    label: 'Discussion',
    path: 'discussion',
    component: DataProductSelectedDiscussion,
  },
  {
    label: 'Roles',
    path: 'roles',
    component: DataProductSelectedRoles,
    requiredAppAccess: AppAccess.ExploreDataProductsWrite,
  },
  {
    label: 'Users',
    path: 'users',
    component: DataProductSelectedUsers,
    requiredAppAccess: AppAccess.ExploreDataProductsWrite,
  },
  {
    label: 'Service Accounts',
    path: 'service_accounts',
    component: DataProductSelectedServiceAccounts,
    requiredAppAccess: AppAccess.ExploreDataProductsWrite,
  },
];
const DataProductSelected = (props: Props) => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [tabIndex, setTabIndex] = useState<number | undefined>();
  const { logonUser } = useAppSelector(s => s.user);
  const toast = useToast();

  const productId = parseInt(params.dataProductId || '0');

  const { data } = useGetDataProductSelectDetailByProductIdQuery(productId, {
    skip: !productId,
  });

  useEffect(() => {
    if (data?.disabled_flag) {
      toast({ description: 'Data product already deleted', status: 'error' });
      navigate('/data-products', { replace: true });
    }
  }, [data]);

  useEffect(() => {
    addBreadcrumb({ label: 'Selected', path: location.pathname });
  }, []);

  useEffect(() => {
    if (tabIndex !== undefined && params.selectedTab !== tabs[tabIndex].path) {
      navigate('./../' + tabs[tabIndex].path + location.search);
    }
  }, [tabIndex]);

  useEffect(() => {
    const paramTab = params.selectedTab;

    if (paramTab !== undefined) {
      const pathIndex = tabs.findIndex(f => f.path === paramTab);
      pathIndex !== tabIndex && setTabIndex(pathIndex);
    }
  }, [params]);

  const handleTabsChange = (index: number) => {
    setTabIndex(index);
  };

  if (data?.disabled_flag) return null;

  return (
    <VStack w="100%">
      <HStack>
        <Heading whiteSpace="initial" size="md" noOfLines={1} maxW="800px" title={params.dataProduct}>
          {data?.data_product_name || params.dataProduct}
        </Heading>
        {data?.dp_state_name && data.dp_state_color && data.dp_state_description && (
          <Tooltip
            placement="right"
            label={<Text dangerouslySetInnerHTML={{ __html: data.dp_state_description }} sx={DefaultInnerHtmlStyle} />}
          >
            <Tag variant="solid" bgColor={data.dp_state_color}>
              {data.dp_state_name}
            </Tag>
          </Tooltip>
        )}
        {logonUser && (
          <RequestAccessDialog
            email={logonUser?.email}
            dp_data_product_id={productId}
            triggerElement={
              <Button variant="outline" colorScheme="brand.main" leftIcon={<FaUnlockAlt />}>
                Request Access
              </Button>
            }
          />
        )}
      </HStack>
      <Divider />

      {tabIndex !== undefined && (
        <Tabs index={tabIndex} onChange={handleTabsChange}>
          <TabList>
            {tabs.map((m, i) =>
              m.requiredAppAccess ? (
                <AppAuth key={i} requiredAppAccess={m.requiredAppAccess}>
                  <Tab>{m.label}</Tab>
                </AppAuth>
              ) : (
                <Tab key={i}>{m.label}</Tab>
              ),
            )}
          </TabList>
          <TabPanels>
            <Box p={3}>{tabs[tabIndex] && createElement(tabs[tabIndex].component)}</Box>
          </TabPanels>
        </Tabs>
      )}
    </VStack>
  );
};

export default DataProductSelected;
