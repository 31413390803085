import { Box, Link } from '@chakra-ui/react';
import { FC, useEffect, useMemo, useState } from 'react';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import { CellProps } from 'react-table';
import { AppSize } from '../../../app/constants';
import { convertUtcToLocal } from '../../../app/helpers/dateHelper';
import { ensureSafeDecodeUri } from '../../../app/helpers/utilities';
import {
  useGetRhPortfolioFilterListQuery,
  useLazyGetRhPortfolioListQuery,
} from '../../../app/services/dme/api/reportHub';
import { ReportHubExploreModel, RhPortfolioFilterModel } from '../../../app/services/dme/api/types';
import { SortType } from '../../../app/services/types';
import { useAppSelector } from '../../../app/state/hooks';
import { CustomTableHeader, DynamicObject } from '../../../app/types/appType';
import CustomTable from '../../../components/CustomTable';
import ExplorePortfoliosActionCell from './ActionCell';

type Props = {};
const InitialSortBy: SortType<ReportHubExploreModel> = {
  id: 'report_portfolio_name',
  desc: false,
};

const ResultData: FC<Props> = () => {
  const [lazyLoadDataLength, setLazyLoadDataLength] = useState(0);
  const [portfolios, setPortfolios] = useState<ReportHubExploreModel[]>([]);

  const [searchParams, setSearchParams] = useSearchParams();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(30);
  const [sortBy, setSortBy] = useState(InitialSortBy);

  const { isSideNavOpen } = useAppSelector(s => s.app);

  const { data: filterData } = useGetRhPortfolioFilterListQuery();
  const [getPortfolios, { isLoading, isFetching, data }] = useLazyGetRhPortfolioListQuery();

  const filters = useMemo(() => {
    const tmpFilters: { filter_header: string; filter_detail: string[] }[] = [];
    searchParams.forEach((value, key) => {
      tmpFilters.push({
        filter_header: key,
        filter_detail: [...ensureSafeDecodeUri(value).split(',')],
      });
    });
    if (tmpFilters.length > 0 && filterData) {
      const initFilters: RhPortfolioFilterModel[] = [];
      tmpFilters.forEach(filter => {
        filter.filter_detail.forEach((detail: string) => {
          const item = filterData.rawData.find(f => f.filter_detail === detail);
          if (item) {
            initFilters.push(item);
          }
        });
      });
      return initFilters.map(({ filter_order, filter_ref_id }) => ({ filter_order, filter_ref_id }));
    }
    return [];
  }, [filterData, searchParams]);

  // const { data, isLoading, isFetching } = useGetRhPortfolioListQuery({
  //   filters,
  //   page_number: pageNumber,
  //   page_size: pageSize,
  //   search_string: searchParams.get('search') ?? '',
  //   sort_column: sortBy.id,
  //   sort_order: sortBy.desc ? 'desc' : 'asc',
  // });

  const fetchPagedPortfolios = async () => {
    const nextPage = pageNumber + 1;

    const response = await getPortfolios({
      filters,
      page_number: nextPage,
      page_size: pageSize,
      search_string: searchParams.get('search') ?? '',
      sort_column: sortBy.id,
      sort_order: sortBy.desc ? 'desc' : 'asc',
    }).unwrap();

    const newRecords: ReportHubExploreModel[] = [];

    setPortfolios(s => [...s, ...response.data]);
    if (newRecords.length === 0) {
      forceRemoveLazyLoaderText();
    }
    setPageNumber(nextPage);
  };

  // (workaround)force remove the infinite loader text if data is empty
  const forceRemoveLazyLoaderText = () => {
    setLazyLoadDataLength(s => ++s);
  };

  const sideNavWidth = isSideNavOpen ? AppSize.sideNav.width.open : AppSize.sideNav.width.close;

  useEffect(() => {
    // needed for search and filters
    setPageNumber(1);
  }, [searchParams]);

  useEffect(() => {
    setLazyLoadDataLength(portfolios.length);
  }, [portfolios]);

  useEffect(() => {
    const run = async () => {
      setPageNumber(1);
      setPortfolios([]);
      const response = await getPortfolios({
        filters,
        page_number: 1,
        page_size: pageSize,
        search_string: searchParams.get('search') ?? '',
        sort_column: sortBy.id,
        sort_order: sortBy.desc ? 'desc' : 'asc',
      }).unwrap();
      setPortfolios(response.data);

      const tableHeadDiv = document.getElementById('infinite-scroll-table-head');
      if (tableHeadDiv) {
        tableHeadDiv.scrollIntoView({});
        window.scrollTo(0, 0);
      }
    };
    run();
  }, [searchParams.get('search'), sortBy, filters]);

  return (
    <Box p={3} borderWidth="1px" borderRadius="lg">
      <CustomTable
        variant="infinite-scroll-table"
        isLoading={isLoading}
        isFetching={isFetching}
        data={portfolios}
        pageCount={0}
        pageSize={pageSize}
        totalRecords={data?.total_records || 0}
        pageIndex={pageNumber - 1}
        headers={Header}
        search={searchParams.get('search') ?? ''}
        initialState={{ sortBy: [InitialSortBy] }}
        manualSortBy
        disableSortRemove
        onPageChange={index => {
          // setPageNumber(index + 1);
        }}
        onPageSizeChange={size => {
          // setPageNumber(1);
          // setPageSize(size);
        }}
        onPageSearchDebounce={400}
        onPageSearch={search => {
          // setPageNumber(1);

          const params: DynamicObject = {};
          searchParams.forEach((val, key) => (params[key] = val));
          setSearchParams({ ...params, search: search }, { replace: true });
        }}
        onSort={sort => {
          if (sort[0]) {
            // setPageNumber(1);
            setSortBy(sort[0]);
          }
        }}
        manual={true}
        infiniteScrollProps={{
          props: {
            dataLength: lazyLoadDataLength,
            next: fetchPagedPortfolios,
            hasMore: pageNumber < (data?.total_pages ?? 0),
            loader: portfolios.length ? <h5>Loading...</h5> : <></>,
            scrollableTarget: 'scrollableDiv',
          },
          parentProps: {
            style: {
              height: 'calc(100dvh - 240px)',
              overflow: 'auto',
            },
          },
        }}
        styles={{
          pagination: { justifyContent: 'start' },
          header: { justifyContent: 'left' },
          tableContainer: {
            sx: {
              maxW: `calc(100vw - 5.75rem - 300px - ${sideNavWidth})`,
              overflow: 'auto',
              table: {
                borderCollapse: 'separate',
                borderSpacing: '0',
                'thead > tr': {
                  position: 'sticky',
                  left: 0,
                  top: 0,
                  zIndex: 2,
                  height: 'auto',
                  bg: 'white',
                },
                tr: {
                  'th:last-child': {
                    position: 'sticky',
                    right: '0px',
                    zIndex: 2,
                    bg: 'white',
                    w: '90px',
                    borderLeft: '1px',
                    borderColor: 'gray.100',
                  },
                  'td:last-child': {
                    position: 'sticky',
                    right: '0px',
                    zIndex: 2,
                    bg: 'white',
                    w: '90px',
                    borderLeft: '1px',
                    borderColor: 'gray.100',
                  },
                },
              },
            },
          },
        }}
      />
    </Box>
  );
};

const Header: CustomTableHeader<ReportHubExploreModel> = [
  {
    Header: 'Name',
    accessor: 'report_portfolio_name',
    styles: { verticalAlign: 'top' },
    Cell: ({ row: { original } }: CellProps<ReportHubExploreModel>) => {
      return (
        <Link as={RouterLink} color="links" to={`./selected/id/${original.rh_report_portfolio_id}/details`}>
          {original.report_portfolio_name}
        </Link>
      );
    },
  },
  {
    Header: 'Squad',
    accessor: 'domain_name',
    styles: { verticalAlign: 'top' },
  },
  {
    Header: 'Description',
    accessor: 'report_portfolio_desc',
    isSortable: false,
    styles: { minWidth: '350px', whiteSpace: 'initial', verticalAlign: 'top' },
  },
  {
    Header: 'Last Modified Date',
    isSortable: false,
    styles: { verticalAlign: 'top' },
    Cell: ({ row: { original } }: CellProps<ReportHubExploreModel>) => {
      return <>{convertUtcToLocal(original.last_modified_datetime_utc) || '-'}</>;
    },
  },
  {
    Header: 'Action',
    Cell: ExplorePortfoliosActionCell,
    styles: { verticalAlign: 'top' },
  },
];

export default ResultData;
