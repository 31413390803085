import { dmeApi } from '.';
import { createFormData } from '../../../helpers/formHelper';
import {
  GetCustomRequestByRequestorIdModel,
  GetCustomRequestByStatusIdModel,
  GetCustomRequestMyRequestModel,
  GetCustomRequestSelectedDetailsModel,
  GetCustomRequestSelectedStatusModel,
  GetCustomRequestsWithApproversModel,
  PostCustomRequestDto,
  PostCustomRequestStatusDto,
  UpdateCustomRequestClaimModel,
} from './types';

const extendedApi = dmeApi.injectEndpoints({
  endpoints: builder => ({
    getCustomRequestByStatusId: builder.query<GetCustomRequestByStatusIdModel[], number>({
      query: status_id => `/customrequest/status_id/${status_id}`,
      providesTags: ["CustomRequest"],
    }),

    getCustomRequestByStatusIdWithApprovers: builder.query<GetCustomRequestsWithApproversModel, number>({
      query: status_id => `/customrequest/status_id/${status_id}/approvers`,
      providesTags: ["CustomRequest"],
    }),

    getCustomRequestByCrId: builder.query<GetCustomRequestByStatusIdModel, number>({
      query: cr_custom_request_id => `/customrequest/cr_custom_request_id/${cr_custom_request_id}`,
      transformResponse: (response: GetCustomRequestByStatusIdModel[]) => {
        return response[0];
      },
    }),

    getCustomRequestByRequestorId: builder.query<GetCustomRequestByRequestorIdModel[], number>({
      query: requestor_id => `/customrequest/requestor_id/${requestor_id}`,
    }),

    getCustomRequestMyRequest: builder.query<GetCustomRequestMyRequestModel, void>({
      query: () => `/customrequest/my-requests`,
    }),

    getCustomRequestSelectedDetails: builder.query<GetCustomRequestSelectedDetailsModel, number>({ 
      query: cr_custom_request_id => `/customrequest/selected/details/cr_custom_request_id/${cr_custom_request_id}`,
      providesTags: ["CustomRequest"],
    }),

    getCustomRequestSelectedStatus: builder.query<GetCustomRequestSelectedStatusModel[], number>({
      query: cr_custom_request_id => `/customrequest/selected/status/cr_custom_request_id/${cr_custom_request_id}`,
    }),

    postCustomRequest: builder.mutation<void, PostCustomRequestDto>({
      query: body => ({
        url: '/customrequest',
        method: 'POST',
        body: createFormData(body),
      }),
    }),

    postCustomRequestStatus: builder.mutation<void, PostCustomRequestStatusDto>({
      query: body => ({
        url: '/customrequest/status',
        method: 'POST',
        body: createFormData(body),
      }),
      invalidatesTags: ["CustomRequest"],
    }),

    putCustomRequestClaim: builder.mutation<void, UpdateCustomRequestClaimModel>({
      query: body => ({
        url: '/customrequest/claim',
        method: 'PUT',
        body,
      }),
      invalidatesTags: ["CustomRequest"],
    }),
  }),
});

export const {
  usePostCustomRequestMutation,
  useLazyGetCustomRequestByStatusIdQuery,
  useLazyGetCustomRequestByStatusIdWithApproversQuery,
  useGetCustomRequestByCrIdQuery,
  useLazyGetCustomRequestByCrIdQuery,
  useGetCustomRequestByRequestorIdQuery,
  useGetCustomRequestMyRequestQuery,
  useGetCustomRequestSelectedDetailsQuery,
  useGetCustomRequestSelectedStatusQuery,
  usePostCustomRequestStatusMutation,
  usePutCustomRequestClaimMutation,
} = extendedApi;
