import {
    Box,
    Divider,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Heading,
    VStack,
    Flex,
    ButtonGroup,
    Button,
    Alert,
    AlertIcon,
    Select,
    HStack,
  } from '@chakra-ui/react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { AddEmployeeOffboardingRequestModel} from '../../../app/services/dme/api/types';
import { useState, useEffect } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { useGetEoOnboardUserListQuery } from '../../../app/services/dme/api/user';
import { useAddEmployeeOffboardingRequestMutation } from '../../../app/services/dme/api/employeeOnboardingRequest';

dayjs.extend(utc);
dayjs.extend(timezone);
 

  const FormSchema = Yup.object().shape({
    ref_user_id: Yup.number().label('Employee').required().moreThan(0, 'Employee is a required field'),
  });
  
  const Offboarding = () => {
    const [alertMessage, setAlertMessage] = useState('');

    // Set the default date to today's date but at 12:00 AM
    const [startDate, setStartDate] = useState(() => {
      const date = new Date();
      date.setHours(0, 0, 0, 0); // Set time to 12:00 AM
      return date;
    });
  
    const [postAsync, postDetail] = useAddEmployeeOffboardingRequestMutation();
    const usersDetails = useGetEoOnboardUserListQuery();
  
    const { handleSubmit, errors, touched, values, setValues, resetForm } = useFormik<AddEmployeeOffboardingRequestModel>(
      {
        enableReinitialize: true,
        validationSchema: FormSchema,
        initialValues: {
          ref_user_id: 0,
          end_date: '',
        },
        onSubmit: values => {
          if (!startDate) {
            alert("Please select both date and time.");
            return;
          }
      
          const formattedDate = dayjs(startDate)
            .second(0)
            .format("YYYY-MM-DD HH:mm:ss");

          //set end_date values
          values.end_date = formattedDate;

          postAsync(values);
        },
      },
    );

    const clearModal = () => {
      resetForm();
      setAlertMessage('');
      setStartDate(() => {
        const date = new Date();
        date.setHours(0, 0, 0, 0); // Set time to 12:00 AM
        return date;
      });
    };

    const handleDateChange = (date: Date | null) => {
      if (date) {
        setStartDate(date);
      }
    };

    useEffect(() => {
      const { isSuccess, isError, isLoading } = postDetail;
  
      if (isSuccess) {
        clearModal();
        setAlertMessage('Request successfully submitted.');
        setTimeout(() => {
          setAlertMessage('');
        }, 5000);
      } else if (isError) {
        setAlertMessage('There was an error processing your request, please try again later.');
      } else {
        setAlertMessage('');
      }
  
      if (isLoading) {
        setAlertMessage('');
      }
    }, [postDetail]);
  
    return (
      <VStack>
        <Heading size="md">Offboarding Request</Heading>
        <Divider />
  
        <form onSubmit={handleSubmit}>
          <VStack spacing="5" pt={5} w="container.sm">
          <FormControl isInvalid={!!errors.ref_user_id && !!touched.ref_user_id} display="flex">
            <FormLabel minW="150px">Employee</FormLabel>
            <Box w="100%">
                <Select
                size="sm"
                id="ref_user_id"
                name="ref_user_id"
                placeholder={usersDetails.isLoading || usersDetails.isFetching ? 'Loading...' : 'Select option'}
                isDisabled={usersDetails.isLoading || usersDetails.isFetching}
                width= '475px'
                value={values.ref_user_id}  // Fix: Provide the actual value directly
                onChange={(e) => {
                    const selectedOption = usersDetails.data?.find(
                    (user) => user.ref_user_id === Number(e.target.value) // Fix: Cast to number to match the `value` type
                    );
                    setValues({
                    ...values,
                    ref_user_id: selectedOption ? selectedOption.ref_user_id : 0,  // Fix: Update the correct value
                    });
                }}
                >
                    {(usersDetails.data ?? []).map(m => (
                        <option key={m.ref_user_id} value={m.ref_user_id}>
                        {m.first_name} {m.last_name}
                        </option>
                    ))}
                </Select>
                <FormErrorMessage>{errors.ref_user_id}</FormErrorMessage>
            </Box>
            </FormControl>

            <FormControl mb={4}>
              <Flex>  
                <FormLabel minW="150px" >End Date</FormLabel>
                <Box w="100%">
                <HStack>
                  {/* Date Picker */}
                  <div style={{
                      border: '1px solid #ccc',
                      padding: '8px',
                      borderRadius: '4px',
                      width: 'auto'
                  }}>
                    <DatePicker
                          selected={startDate}
                          onChange={handleDateChange}
                          timeInputLabel="Time:"
                          dateFormat="yyyy-MM-dd h:mm aa"
                          timeIntervals={30}
                          showTimeInput
                          showIcon
                          required
                          toggleCalendarOnIconClick
                        />
                  </div>

                  {/* Static Timezone Display */}
                  <Box>Central US Time</Box> 
                  </HStack>
                </Box>
              </Flex> 
            </FormControl>

            {alertMessage && (
              <Alert status={postDetail.isSuccess ? 'success' : 'error'}>
                <AlertIcon />
                {alertMessage}
              </Alert>
            )}
  
            <ButtonGroup>
              <Button isDisabled={postDetail.isLoading} variant="outline" onClick={() => clearModal()} ml="auto">
                Cancel
              </Button>
              <Button isLoading={postDetail.isLoading} variant="solid" colorScheme="brand.main" type="submit">
                Submit
              </Button>
            </ButtonGroup>
          </VStack>
        </form>
      </VStack>
    );
  };
  
  export default Offboarding;
  