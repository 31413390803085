import { Box, Text, HStack, Stack, Grid, GridItem, Collapse, IconButton, Heading } from '@chakra-ui/react';
import { useGetEmployeeOnboardingRequestApprovalListQuery } from '../../../app/services/dme/api/employeeOnboardingRequest';
import { useAppSelector } from '../../../app/state/hooks';
import { useState } from 'react';
import { MdCompress, MdExpand } from 'react-icons/md';
import { format } from 'date-fns';

type Props = {};

const EmployeeOnboardingRequestApproval = (props: Props) => {
  //get user info
  const { logonUser } = useAppSelector(s => s.user);
  const userId = logonUser?.ref_user_id ?? 0;
  const { data, isLoading, isFetching } = useGetEmployeeOnboardingRequestApprovalListQuery(userId);
  const [expanded, setExpanded] = useState<{ [key: number]: boolean }>({});

  const onboardStat = 'New';
  const offboardStat = 'New offboarding';
  const softwareLicenseId = 6;

  if (isLoading || isFetching) return <div>Loading...</div>;

  //Add a check to ensure data is not undefined or null
  if(!data || !data.first_dataset_model  || data.first_dataset_model.length === 0){
    return <div> No data available</div>
  }

  const formatDate = (dateString: string) => {
    const date = new Date(dateString); // Convert the string to a Date object
    const formattedDate = format(date, 'yyyy-MM-dd hh:mm a');
    
    // If time is exactly 00:00, return just the date in yyyy-MM-dd format
    if (date.getHours() === 0 && date.getMinutes() === 0) {
      return format(date, 'yyyy-MM-dd');
    }

    return formattedDate + ' CST';
  };

   
  return (
    <>
      <Box p="3">
        {/* Onboarding Section */}
        <Box maxH= '100%' overflowY="auto">
          <Heading size="md" mb={4}>Onboarding Requests</Heading>
          {data.first_dataset_model.filter(d => d.request_status_name === onboardStat).length > 0 ? (
            data.first_dataset_model.filter(d => d.request_status_name === onboardStat).map((d, i) => (
              <Box p={3} my={4} shadow="md" border="1px" borderColor="gray.200" borderRadius={3} key={i}>
                <HStack alignItems="start">
                  <Box>
                    <HStack flexShrink={0} mr="2">
                      <IconButton
                        aria-label={expanded[d.eo_request_id] ? 'contract card' : 'expand card'}
                        title={expanded[d.eo_request_id] ? 'Collapse' : 'Expand'}
                        size="sm" icon={expanded[d.eo_request_id] ? <MdCompress /> : <MdExpand />}
                        onClick={() =>
                          setExpanded(e => ({
                            ...e,
                            [d.eo_request_id]: !e[d.eo_request_id]
                          }))
                        }
                      />
                    </HStack>
                  </Box>
                  <Stack w="100%">
                    <Grid templateColumns="repeat(6, 1fr)" gap={3}>
                      <Field title="Request ID" value={d.eo_request_id} />
                      <Field title="First Name" value={`${d.first_name}`} />
                      <Field title="Last Name" value={`${d.last_name}`} />
                      <Field title="Employee Type" value={d.employee_type? d.employee_type:'-'} />
                      <Field title="Start Date" value={d.start_date? formatDate(d.start_date) : '-'} />
                      <Field title="End Date" value={d.end_date? formatDate(d.end_date) : '-'} />
                    </Grid>
                    <Collapse in={expanded[d.eo_request_id]}>
                      <Grid templateColumns="repeat(6, 1fr)" gap={3}>
                        <Field title="Personal Email" value={d.personal_email_address} />
                        <Field title="Contact Number" value={d.contact_number? d.contact_number:'-'} />
                        <Field title="Geographical Location" value={d.geographical_location? d.geographical_location: '-'} />
                        <Field title="Address 1" value={d.shipping_address_line_1 ? d.shipping_address_line_1: '-'} />
                        <Field title="Address 2" value={d.shipping_address_line_2 ? d.shipping_address_line_2: '-'} />
                        <Field title="City" value={d.shipping_address_city ? d.shipping_address_city : '-' } />
                      </Grid>
                      <Grid templateColumns="repeat(6, 1fr)" gap={3}>
                        <Field title="State" value={d.shipping_address_state? d.shipping_address_state: '-'} />
                        <Field title="Country" value={d.shipping_address_country ? d.shipping_address_country : '-' } />
                        <Field title="ZIP Code" value={d.shipping_address_zip ? d.shipping_address_zip: '-'} />
                        <Field title="Squad" value={d.squad? d.squad:'-'} />
                        <Field title="Specialty" value={d.specialty? d.specialty: '-'} />   
                        <Field title="Needs Virtual Machine?" value={d.virtual_machine_flag? 'YES':'NO'} />
                      </Grid>
                      <Grid templateColumns="repeat(6, 1fr)" gap={3}>
                        <Field title="Requested by" value={d.requested_by? d.requested_by: '-'} />
                        <Field title="Requested Date" value={d.row_created_datetime_utc? d.row_created_datetime_utc: '-'} />
                        <Field title="Additional Notes" value={d.additional_notes? d.additional_notes: '-'} colSpan={4}/>
                      </Grid>
                      {/* Display answers from second_dataset_model for current eo_request_id */}
                      {data.second_dataset_model.filter((q) => q.eo_request_id === d.eo_request_id).length> 0 &&(
                          <HStack gap={8}>
                          {/* Display Virtual Machine and Hardware with one answer each */}
                          {data.second_dataset_model
                            .filter((q) => q.eo_request_id === d.eo_request_id && (q.eo_ref_question_id !== softwareLicenseId ))
                            .map((q, j) => (
                              <Grid templateColumns="repeat(1, 1fr)" gap={3} key={`q-${j}`}>
                                <Field title={q.question_instruction_text} value={q.pk_name} />
                              </Grid>
                            ))
                          }
                          
                          {/* Display Software Licenses with multiple items, separated by commas */}
                          {data.second_dataset_model
                            .filter((q) => q.eo_request_id === d.eo_request_id && q.eo_ref_question_id === softwareLicenseId)
                            .length > 0 && (
                              <Grid templateColumns="repeat(1, 1fr)" gap={3}>
                                <Field
                                  title="Software Licenses"
                                  value={data.second_dataset_model
                                    .filter((q) => q.eo_request_id === d.eo_request_id && q.eo_ref_question_id === softwareLicenseId)
                                    .map((q) => q.pk_name || '-')
                                    .join(', ')}
                                />
                              </Grid>
                            )
                          }
                        </HStack>
                      )}
                    </Collapse>
                  </Stack>
                </HStack>
              </Box>
            ))):(
              <Text style={{ marginLeft: 20}}>No data found</Text>
          )}
        </Box>

        {/*Divider */}
        <hr style={{
            border: "1px solid brand.main.default",
            marginBottom: '10px',
            marginTop: '10px',
            width: '100%',
        }}/>

        {/* Offboarding Section */}
        <Box maxH="100%" overflowY="auto">
          <Heading size="md" mb={4}>Offboarding Requests</Heading>
          {data.first_dataset_model.filter(d => d.request_status_name === offboardStat).length > 0 ? (
            data.first_dataset_model.filter(d => d.request_status_name === offboardStat).map((d, i) => (
              <Box p={3} my={4} shadow="md" border="1px" borderColor="gray.200" borderRadius={3} key={i}>
                <HStack alignItems="start">
                <Box>
                    <HStack flexShrink={0} mr="2">
                      <IconButton
                        aria-label={expanded[d.eo_request_id] ? 'contract card' : 'expand card'}
                        title={expanded[d.eo_request_id] ? 'Collapse' : 'Expand'}
                        size="sm" icon={expanded[d.eo_request_id] ? <MdCompress /> : <MdExpand />}
                        onClick={() =>
                          setExpanded(e => ({
                            ...e,
                            [d.eo_request_id]: !e[d.eo_request_id]
                          }))
                        }
                      />
                    </HStack>
                  </Box>
                  <Stack w="100%">
                    {/* <Text fontSize="inherit" fontWeight="bold">Offboarding</Text> */}
                    <Grid templateColumns="repeat(4, 1fr)" gap={3}>
                      <Field title="Request ID" value={d.eo_request_id} />
                      <Field title="First Name" value={`${d.first_name}`} />
                      <Field title="Last Name" value={`${d.last_name}`} />
                      <Field title="End Date" value={formatDate(d.end_date)} />
                    </Grid>
                    <Collapse in={expanded[d.eo_request_id]}>
                      <Grid templateColumns="repeat(4, 1fr)" gap={3}>
                        <Field title="Requested by" value={d.requested_by? d.requested_by: '-'} />
                        <Field title="Requested Date" value={d.row_created_datetime_utc? d.row_created_datetime_utc : '-'} />
                      </Grid>
                    </Collapse>
                  </Stack>
                </HStack>
              </Box>
            ))):(
              <Text style={{ marginLeft: 20}}>No data found</Text>
          )}
        </Box>
      </Box>
    </>
  );
};

type FieldProps = {
  title: string;
  value: any;
  w?: string | number;
  maxW?: string | number;
  colSpan?: number;
};
function Field({ title, value, w, maxW, colSpan }: FieldProps) {
  return (
    <GridItem colSpan={colSpan}>
      <Stack w={w} maxW={maxW} spacing={0}>
        <Text fontWeight="bold">{title}</Text>
        <Text>{value}</Text>
      </Stack>
    </GridItem>
  );
}

export default EmployeeOnboardingRequestApproval;