import { Box, Text } from '@chakra-ui/react';
import { FC, useEffect, useMemo, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { AppSize } from '../../../../app/constants';
import { convertUtcToLocal } from '../../../../app/helpers/dateHelper';
import { ensureSafeDecodeUri } from '../../../../app/helpers/utilities';
import {
  useGetDataProductResultsPageFilterQuery,
  useLazyGetDataProductResultQuery,
} from '../../../../app/services/dme/api/dataProduct';
import { DataProductResultModel, DataProductResultsPageFilterModel } from '../../../../app/services/dme/api/types';
import { SortType } from '../../../../app/services/types';
import { useAppSelector } from '../../../../app/state/hooks';
import { DynamicObject } from '../../../../app/types/appType';
import CustomTable from '../../../../components/CustomTable';

type Props = {};
const InitialSortBy: SortType<DataProductResultModel> = {
  id: 'data_product_name',
  desc: false,
};

const Result: FC<Props> = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(30);
  const [sortBy, setSortBy] = useState(InitialSortBy);
  const [lazyLoadDataLength, setLazyLoadDataLength] = useState(0);
  const [dataProducts, setDataProducts] = useState<DataProductResultModel[]>([]);

  const { isSideNavOpen } = useAppSelector(s => s.app);

  const { data: filterData } = useGetDataProductResultsPageFilterQuery();

  const filters = useMemo(() => {
    const tmpFilters: { filter_header: string; filter_detail: string[] }[] = [];
    searchParams.forEach((value, key) => {
      tmpFilters.push({
        filter_header: key,
        filter_detail: [...ensureSafeDecodeUri(value).split(',')],
      });
    });
    if (tmpFilters.length > 0 && filterData) {
      const initFilters: DataProductResultsPageFilterModel[] = [];
      tmpFilters.forEach(filter => {
        filter.filter_detail.forEach((detail: string) => {
          const item = filterData.rawData.find(f => f.filter_detail === detail);
          if (item) {
            initFilters.push(item);
          }
        });
      });
      return initFilters.map(({ filter_order, filter_ref_id }) => ({ filter_order, filter_ref_id }));
    }
    return [];
  }, [filterData, searchParams]);

  const [getDataProducts, { isLoading, isFetching, data }] = useLazyGetDataProductResultQuery();

  const fetchPagedDataProducts = async () => {
    const nextPage = pageNumber + 1;

    const response = await getDataProducts({
      filters,
      page_number: nextPage,
      page_size: pageSize,
      search_string: searchParams.get('search') ?? '',
      sort_column: sortBy.id,
      sort_order: sortBy.desc ? 'desc' : 'asc',
    }).unwrap();

    const newRecords: DataProductResultModel[] = [];

    setDataProducts(s => [...s, ...response.data]);
    if (newRecords.length === 0) {
      forceRemoveLazyLoaderText();
    }
    setPageNumber(nextPage);
  };

  // (workaround)force remove the infinite loader text if data is empty
  const forceRemoveLazyLoaderText = () => {
    setLazyLoadDataLength(s => ++s);
  };

  const sideNavWidth = isSideNavOpen ? AppSize.sideNav.width.open : AppSize.sideNav.width.close;

  useEffect(() => {
    // needed for search and filters
    setPageNumber(1);
  }, [searchParams]);

  useEffect(() => {
    setLazyLoadDataLength(dataProducts.length);
  }, [dataProducts]);

  useEffect(() => {
    const run = async () => {
      setPageNumber(1);
      setDataProducts([]);
      const response = await getDataProducts({
        filters,
        page_number: 1,
        page_size: pageSize,
        search_string: searchParams.get('search') ?? '',
        sort_column: sortBy.id,
        sort_order: sortBy.desc ? 'desc' : 'asc',
      }).unwrap();
      setDataProducts(response.data);

      const tableHeadDiv = document.getElementById('infinite-scroll-table-head');
      if (tableHeadDiv) {
        tableHeadDiv.scrollIntoView({});
        window.scrollTo(0, 0);
      }
    };
    run();
  }, [searchParams.get('search'), sortBy, filters]);

  return (
    <Box p={3} borderWidth="1px" borderRadius="lg">
      <CustomTable
        variant="infinite-scroll-table"
        isLoading={isLoading}
        isFetching={isFetching}
        data={dataProducts}
        pageCount={0}
        pageSize={pageSize}
        totalRecords={data?.total_records || 0}
        pageIndex={pageNumber - 1}
        headers={Header}
        search={searchParams.get('search') ?? ''}
        initialState={{ sortBy: [InitialSortBy] }}
        manualSortBy
        disableSortRemove
        onPageChange={index => {
          // setPageNumber(index + 1);
        }}
        onPageSizeChange={size => {
          // setPageNumber(1);
          // setPageSize(size);
        }}
        onPageSearchDebounce={400}
        onPageSearch={search => {
          const params: DynamicObject = {};
          searchParams.forEach((val, key) => (params[key] = val));
          setSearchParams({ ...params, search: search }, { replace: true });
        }}
        onSort={sort => {
          if (sort[0]) {
            setSortBy(sort[0]);
          }
        }}
        styles={{
          pagination: { justifyContent: 'start' },
          header: { justifyContent: 'left' },
          tableContainer: {
            sx: {
              maxW: `calc(100vw - 5.75rem - 300px - ${sideNavWidth})`,
              table: {
                borderCollapse: 'separate',
                borderSpacing: '0',
              },
            },
          },
        }}
        manual={true}
        infiniteScrollProps={{
          props: {
            dataLength: lazyLoadDataLength,
            next: fetchPagedDataProducts,
            hasMore: pageNumber < (data?.total_pages ?? 0),
            loader: dataProducts.length ? <h5>Loading...</h5> : <></>,
            scrollableTarget: 'scrollableDiv',
          },
          parentProps: {
            style: {
              height: 'calc(100dvh - 240px)',
              overflow: 'auto',
            },
          },
        }}
      />
    </Box>
  );
};

const Header = [
  {
    Header: 'Squad',
    accessor: 'domain_name',
    styles: { whiteSpace: 'initial' },
    Cell: ({ row: { original } }: { row: { original: DataProductResultModel } }) => {
      return (
        <Box maxW="200px">
          <Text noOfLines={1} title={original.domain_name}>
            {original.domain_name}
          </Text>
        </Box>
      );
    },
  },
  {
    Header: 'Data Product Name',
    accessor: 'data_product_name',
    styles: { whiteSpace: 'initial' },
    Cell: ({ row: { original } }: { row: { original: DataProductResultModel } }) => {
      return (
        <Link
          to={`../selected/domain/${encodeURIComponent(original.domain_name)}/data-product/${encodeURIComponent(
            original.data_product_name,
          )}/id/${original.dp_data_product_id}/details`}
        >
          <Text as="span" color="links" wordBreak="break-word">
            {original.data_product_name}
          </Text>
        </Link>
      );
    },
  },
  {
    Header: 'State',
    // accessor: "data_product_state",
    styles: { whiteSpace: 'initial' },
    Cell: ({ row: { original } }: { row: { original: DataProductResultModel } }) => {
      return (
        <Box maxW="200px">
          <Text
            noOfLines={1}
            title={original.data_product_state}
            color={original.data_product_state_color}
            fontWeight="bold"
          >
            {original.data_product_state}
          </Text>
        </Box>
      );
    },
  },
  {
    Header: 'Description',
    accessor: 'data_product_desc',
    styles: { whiteSpace: 'initial' },
    isSortable: false,
    Cell: ({ row: { original } }: { row: { original: DataProductResultModel } }) => {
      return (
        <Box maxW="800px">
          <Text noOfLines={3} title={original.data_product_desc}>
            {original.data_product_desc}
          </Text>
        </Box>
      );
    },
  },
  {
    Header: 'Type',
    accessor: 'data_product_type',
    isSortable: false,
  },
  {
    Header: 'Developer',
    accessor: 'email',
    styles: { whiteSpace: 'nowrap' },
    isSortable: false,
  },
  {
    Header: 'Last Modified Date',
    accessor: 'last_modified_datetime_utc',
    Cell: ({ row: { original } }: { row: { original: DataProductResultModel } }) => {
      return <>{convertUtcToLocal(original.last_modified_datetime_utc) || '-'}</>;
    },
  },
];

export default Result;
