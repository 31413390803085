import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Collapse,
  FormControl,
  FormErrorMessage,
  Grid,
  GridItem,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  Textarea,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { convertUtcToLocal } from '../../../app/helpers/dateHelper';
import {
  AccessAdminApprovalDataSet2Model,
  AccessAdminApprovalModel,
  AccessAdminApprovalUserAfModel,
  RefUserModel,
} from '../../../app/services/dme/api/types';
import { IoExpand, IoContract } from 'react-icons/io5';
import { useEffect, useState } from 'react';
import { usePutUserRequestAccessMutation } from '../../../app/services/dme/api/dataProduct';
import { GoCheck, GoX } from 'react-icons/go';
import { AiOutlineStop } from 'react-icons/ai';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { usePubNub } from 'pubnub-react';
import { useAppSelector } from '../../../app/state/hooks';
import PubNubConstants from '../../../features/PubNubWrapper/constants';
import {
  useGetAccessAdminApprovalUserAfByRefUserIdQuery,
  usePutAccessAdminApprovalDpUserClaimFlagMutation,
} from '../../../app/services/dme/api/accessAdminApproval';
import { ValueOf } from '../../../app/helpers/utilities';
import AppAuth from '../../../features/AppAuth';
import { AppAccess } from '../../../app/constants/appAccesses';
import { useIsUserHasRequiredAppAccess } from '../../../app/hooks/useIsUserHasRequiredRoles';
import { MdCompress, MdExpand } from 'react-icons/md';

type Props = {
  data: AccessAdminApprovalModel;
  refUsers: RefUserModel[];
  setData: (field: keyof AccessAdminApprovalModel, data: ValueOf<AccessAdminApprovalModel>) => void;
};

const FormSchema = Yup.object().shape({
  deny_reason: Yup.string().label('Reason').required().max(1500, 'Text exceed the character limit of 1500'),
});
const _channelName = 'data_product_user';
const openDelay = 1000;

const AccessApprovalDataProductUser = (props: Props) => {
  const {
    data: { second_dataset_model: data },
    refUsers,
    setData,
  } = props;

  const [success, setSuccess] = useState<number[]>([]);
  const [alertMessage, setAlertMessage] = useState('');
  const [expanded, setExpanded] = useState<{ [key: number]: boolean }>({});

  const pubnub = usePubNub();
  const { messageEvent } = useAppSelector(s => s.pubNub);
  const { logonUser } = useAppSelector(s => s.user);
  const userWriteAccess = useIsUserHasRequiredAppAccess('adminaccessapproval.write');

  const afDetails = useGetAccessAdminApprovalUserAfByRefUserIdQuery(logonUser?.ref_user_id ?? 0, {
    skip: (logonUser?.ref_user_id ?? 0) < 1,
  });
  const [putAsync, putDetails] = usePutUserRequestAccessMutation();
  const [putClaimedFlagAsync, putClaimedFlagDetails] = usePutAccessAdminApprovalDpUserClaimFlagMutation();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { handleSubmit, errors, touched, handleChange, values, setValues } = useFormik({
    enableReinitialize: true,
    validationSchema: FormSchema,
    initialValues: {
      id: -1,
      deny_reason: '',
    },
    onSubmit: (values, form) => {
      // will pass here when access is denied
      putAsync({
        dp_user_data_product_delivery_mode_id: values.id,
        access_approved_flag: false,
        access_denied_flag: true,
        access_denied_notes: values.deny_reason.trim(),
      });
      setAlertMessage('');
      onClose();
      form.resetForm();
    },
  });

  useEffect(() => {
    pubnub.subscribe({
      channels: [_channelName],
    });

    return () => {
      pubnub.unsubscribe({
        channels: [_channelName],
      });
    };
  }, [pubnub]);

  useEffect(() => {
    if (messageEvent?.channel === _channelName) {
      const { message } = messageEvent;
      setData(
        'second_dataset_model',
        data.map(item => {
          if (item.dp_user_data_product_delivery_mode_id === message.data.dp_user_data_product_delivery_mode_id) {
            return message.data;
          } else {
            return item;
          }
        }),
      );
    }
  }, [messageEvent]);

  useEffect(() => {
    if (putDetails.isSuccess && putDetails.originalArgs?.dp_user_data_product_delivery_mode_id) {
      setSuccess(s => [...s, putDetails.originalArgs!.dp_user_data_product_delivery_mode_id]);
      setTimeout(() => {
        setData(
          'second_dataset_model',
          data.filter(
            i =>
              i.dp_user_data_product_delivery_mode_id !==
              putDetails.originalArgs!.dp_user_data_product_delivery_mode_id,
          ),
        );
      }, 3000);
    }
    if (putDetails.error) {
      setAlertMessage('There was an error processing your request, please try again later.');
    }
  }, [putDetails]);

  const handleClaim = (data: AccessAdminApprovalDataSet2Model, claim_flag: boolean) => async () => {
    if (logonUser) {
      const newData: AccessAdminApprovalDataSet2Model = {
        ...data,
        claimed_flag: claim_flag,
        claim_user_id: claim_flag ? logonUser.ref_user_id : 0,
      };
      try {
        await pubnub.publish({
          channel: _channelName,
          message: {
            data: newData,
            type: PubNubConstants.MessageEvent.Type.CLAIM,
          },
        });

        await putClaimedFlagAsync({
          dp_user_data_product_delivery_mode_id: data.dp_user_data_product_delivery_mode_id,
          claimed_flag: claim_flag,
          claim_user_id: claim_flag ? logonUser.ref_user_id : 0,
        });
      } catch (ex) {
        await pubnub.publish({
          channel: _channelName,
          message: {
            data: {
              ...newData,
              claimed_flag: !claim_flag,
              claim_user_id: !claim_flag ? logonUser.ref_user_id : 0,
            },
            type: PubNubConstants.MessageEvent.Type.CLAIM,
          },
        });
      }
    }
  };

  const handleApprove = (data: AccessAdminApprovalDataSet2Model) => () => {
    putAsync({
      dp_user_data_product_delivery_mode_id: data.dp_user_data_product_delivery_mode_id,
      access_approved_flag: true,
      access_denied_flag: false,
    });
    setAlertMessage('');
  };

  const handleDeny = (data: AccessAdminApprovalDataSet2Model) => () => {
    setValues({
      id: data.dp_user_data_product_delivery_mode_id,
      deny_reason: '',
    });
    onOpen();
  };

  const isSuccess = (data: AccessAdminApprovalDataSet2Model) => {
    return success.some(id => id === data.dp_user_data_product_delivery_mode_id);
  };

  if (!data) return <></>;

  return (
    <>
      {alertMessage && (
        <Alert status={putDetails.isSuccess ? 'success' : 'error'}>
          <AlertIcon />
          {alertMessage}
        </Alert>
      )}
      {logonUser &&
        data.map((d, i) => {
          const userHasClaimed = data.some(s => s.claim_user_id === logonUser.ref_user_id);
          const claimedBy = refUsers.find(f => f.ref_user_id === d.claim_user_id);
          const claimedByFullName =
            claimedBy && (`${claimedBy.first_name || ''} ${claimedBy.last_name || ''}`.trim() || claimedBy.email);
          const afFields = afDetails.data
            ?.filter(f => f.dp_user_data_product_delivery_mode_id === d.dp_user_data_product_delivery_mode_id)
            .reduce((list, field) => {
              const index = list.findIndex(
                l => l.dp_delivery_mode_additional_field_id === field.dp_delivery_mode_additional_field_id,
              );
              if (index > 0) {
                const item = Object.assign({}, list[index]);

                const commaText = item.field_response_text ? ', ' : '';
                item.field_response_text =
                  (item.field_response_text || '') + commaText + (field.field_response_text || '');

                const commaOption = item.option_name ? ', ' : '';
                item.option_name = (item.option_name || '') + commaOption + (field.option_name || '');

                list[index] = item;
              } else {
                list.push(field);
              }
              return list;
            }, [] as AccessAdminApprovalUserAfModel[]);
          return (
            <Box p={3} my={4} shadow="md" border="1px" borderColor="gray.200" borderRadius={3}>
              <HStack alignItems="start">
                <Box>
                  <HStack flexShrink={0} mr="2">
                    {userWriteAccess && (
                      <HStack w="112px">
                        {d.claimed_flag && d.claim_user_id === logonUser.ref_user_id ? (
                          isSuccess(d) ? (
                            <Alert status="success" paddingY={1} paddingX={2}>
                              <AlertIcon />
                              Success!
                            </Alert>
                          ) : (
                            <>
                              <Tooltip label="Approve" openDelay={openDelay}>
                                <IconButton
                                  aria-label="Approve"
                                  size="sm"
                                  colorScheme="brand.main"
                                  icon={<GoCheck />}
                                  onClick={handleApprove(d)}
                                  isLoading={
                                    putDetails.isLoading &&
                                    putDetails.originalArgs?.access_approved_flag &&
                                    d.dp_user_data_product_delivery_mode_id ===
                                      putDetails.originalArgs?.dp_user_data_product_delivery_mode_id
                                  }
                                  isDisabled={putDetails.isLoading || isSuccess(d)}
                                />
                              </Tooltip>
                              <Tooltip label="Deny" openDelay={openDelay}>
                                <IconButton
                                  aria-label="Deny"
                                  size="sm"
                                  bg="brand.error"
                                  colorScheme="brand.error"
                                  icon={<GoX />}
                                  onClick={handleDeny(d)}
                                  isLoading={
                                    putDetails.isLoading &&
                                    putDetails.originalArgs?.access_denied_flag &&
                                    d.dp_user_data_product_delivery_mode_id ===
                                      putDetails.originalArgs?.dp_user_data_product_delivery_mode_id
                                  }
                                  isDisabled={putDetails.isLoading || isSuccess(d)}
                                />
                              </Tooltip>
                            </>
                          )
                        ) : (
                          <Tooltip label={claimedByFullName}>
                            <AppAuth requiredAppAccess={AppAccess.AdminAccessApprovalWrite}>
                              {isAuthorized => (
                                <Button
                                  size="sm"
                                  colorScheme={d.claimed_flag ? 'orange.800' : 'brand.main'}
                                  bg={d.claimed_flag ? 'orange.400' : 'brand.main.default'}
                                  onClick={d.claimed_flag ? () => {} : handleClaim(d, true)}
                                  isDisabled={
                                    (userHasClaimed && !d.claimed_flag) ||
                                    putDetails.isLoading ||
                                    putClaimedFlagDetails.isLoading ||
                                    !isAuthorized
                                  }
                                >
                                  {d.claimed_flag ? 'Claimed' : 'Claim'}
                                </Button>
                              )}
                            </AppAuth>
                          </Tooltip>
                        )}
                        {d.claimed_flag && !isSuccess(d) && (
                          <AppAuth requiredAppAccess={AppAccess.AdminAccessApprovalWrite}>
                            {isAuthorized => (
                              <Tooltip label="Unclaim" openDelay={openDelay}>
                                <IconButton
                                  aria-label="Unclaim"
                                  size="sm"
                                  icon={<AiOutlineStop />}
                                  onClick={handleClaim(d, false)}
                                  isDisabled={!isAuthorized || putClaimedFlagDetails.isLoading}
                                />
                              </Tooltip>
                            )}
                          </AppAuth>
                        )}
                      </HStack>
                    )}

                    <IconButton
                      aria-label={expanded[d.dp_user_data_product_delivery_mode_id] ? 'expand card' : 'contract card'}
                      size="sm"
                      icon={expanded[d.dp_user_data_product_delivery_mode_id] ? <MdCompress /> : <MdExpand />}
                      onClick={() =>
                        setExpanded(e => ({
                          ...e,
                          [d.dp_user_data_product_delivery_mode_id]: !e[d.dp_user_data_product_delivery_mode_id],
                        }))
                      }
                    />
                  </HStack>
                </Box>

                <Stack w="100%">
                  <Grid templateColumns="repeat(5, 1fr)" gap={3}>
                    <Field title="User" value={`${d.first_name} ${d.last_name}`} />
                    {/* <Field title="Domain" value={d.domain_name} /> */}
                    <Field title="Squad" value={d.domain_name} />
                    <Field title="Data Product Name" value={d.data_product_name} />
                    <Field title="Business Justification" value={d.access_request_business_justification} colSpan={2} />
                  </Grid>
                  <Collapse in={expanded[d.dp_user_data_product_delivery_mode_id]}>
                    <Grid templateColumns="repeat(5, 1fr)" gap={3} pb={2}>
                      <Field title="Request ID" value={d.dp_user_data_product_delivery_mode_id} />
                      <Field title="Expiration Date" value={convertUtcToLocal(d.access_expiration_datetime_utc)} />
                      <Field title="Request Date" value={convertUtcToLocal(d.access_requested_datetime_utc)} />
                    </Grid>
                    <Grid templateColumns="repeat(5, 1fr)" gap={3}>
                      <Field title="Mode" value={d.delivery_mode} />
                      <Field title="Properties" value={d.delivery_mode_properties} valueWordBreak="break-all" />
                      <Field title="Data Product Desc" value={d.data_product_desc} colSpan={3} />
                    </Grid>
                    {(afFields?.length ?? 0) > 0 && (
                      <Grid templateColumns="repeat(4, 1fr)" gap={3}>
                        {afFields?.map((m, i) => (
                          <Field
                            key={i}
                            title={m.additional_field_name}
                            value={m.field_response_text || m.option_name}
                          />
                        ))}
                      </Grid>
                    )}
                  </Collapse>
                </Stack>
              </HStack>
            </Box>
          );
        })}

      <Modal isOpen={isOpen} onClose={onClose} size="2xl" closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Denial Reason</ModalHeader>
          <ModalCloseButton />
          <form onSubmit={handleSubmit}>
            <ModalBody>
              <FormControl isInvalid={!!errors.deny_reason && touched.deny_reason}>
                <Textarea
                  id="deny_reason"
                  placeholder="Enter Reason"
                  name="deny_reason"
                  onChange={handleChange}
                  value={values.deny_reason}
                  maxLength={1500}
                />
                <FormErrorMessage>{errors.deny_reason}</FormErrorMessage>
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Button onClick={onClose} mr={3} ml="auto">
                Cancel
              </Button>
              <Button type="submit" colorScheme="brand.main">
                Deny
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
};

type FieldProps = {
  title: string;
  value: any;
  w?: string | number;
  maxW?: string | number;
  colSpan?: number;
  valueWordBreak?: 'break-all';
};
function Field({ title, value, w, maxW, colSpan, valueWordBreak }: FieldProps) {
  return (
    <GridItem colSpan={colSpan}>
      <Stack w={w} maxW={maxW} spacing={0}>
        <Text fontWeight="bold">{title}</Text>
        <Text wordBreak={valueWordBreak}>{value}</Text>
      </Stack>
    </GridItem>
  );
}

export default AccessApprovalDataProductUser;
