import { dmeApi } from '.';
import { DynamicObject } from '../../../types/appType';
import { PaginatedApiResult, PaginatedQueryParams } from '../../types';
import {
  AddReportHubPortfolioModel,
  ReportHubExploreModel,
  ReportHubMyAccessDetailsModel,
  ReportHubMyAccessStatusModel,
  ReportHubSelectedDetailsModel,
  ReportHubSelectedReportsTabModel,
  ReportHubSelectedRolesTabModel,
  ReportHubSelectedServiceAccountsTabModel,
  ReportHubSelectedTagsTabModel,
  ReportHubSelectedUsersTabModel,
  ReportPortfolioStateModel,
  ReportPortfolioTypeModel,
  ReportStateModel,
  RhPortfolioFilterModel,
  RhPortfolioFilterTransformedModel,
  RhReportHubMyAccessFirstDatasetModel,
  RhReportHubMyAccessSecondDatasetModel,
  RhReportHubMyAccessThirdDatasetModel,
  RhReportModel,
  UpdateReportHubPortfolioModel,
} from './types';

// rhpId = Report Hub Portfolio ID

const baseUrl = '/reports';

const extendedApi = dmeApi.injectEndpoints({
  endpoints: build => ({
    // SP: p_rh_report_portfolios_explore_filters_applied
    getRhPortfolioList: build.query<
      PaginatedApiResult<ReportHubExploreModel>,
      PaginatedQueryParams<ReportHubExploreModel> & {
        filters: { filter_order: number; filter_ref_id: number }[];
      }
    >({
      query: ({ filters, ...params }) => ({
        url: baseUrl + `/portfolio/explore/filters/applied`,
        params: {
          ...params,
          ...filters.reduce((obj, val, i) => {
            obj[`filters[${i}].filter_order`] = val.filter_order;
            obj[`filters[${i}].filter_ref_id`] = val.filter_ref_id;
            return obj;
          }, {} as DynamicObject),
        },
      }),
      providesTags: ['ReportPortfolio'],
    }),

    getRhPortfolioFilterList: build.query<
      { data: RhPortfolioFilterTransformedModel[]; rawData: RhPortfolioFilterModel[] },
      void
    >({
      query: () => baseUrl + `/portfolio/unique/filters/list`,
      transformResponse: (response: RhPortfolioFilterModel[]) => {
        const a = response.reduce((prev, { filter_header, filter_ref_id, filter_detail, filter_order }) => {
          const existingItemIndex = prev.findIndex(
            s => s.filter_header === filter_header && s.filter_order === filter_order,
          );

          if (existingItemIndex > -1) {
            let prevFilterItems = prev[existingItemIndex].filter_items;

            const existingFilterItemsIndex = prevFilterItems.findIndex(s => s.filter_detail === filter_detail);
            if (existingFilterItemsIndex > -1) {
              prevFilterItems[existingFilterItemsIndex] = {
                filter_header,
                filter_detail,
                filter_ref_id,
              };
            } else {
              prevFilterItems = [
                ...prevFilterItems,
                {
                  filter_header,
                  filter_detail,
                  filter_ref_id,
                },
              ];
            }

            const sortedFilterItems = [...prevFilterItems].sort((a, b) => {
              if (a.filter_detail > b.filter_detail) {
                return 1;
              }
              if (b.filter_detail > a.filter_detail) {
                return -1;
              }
              return 0;
            });
            prev[existingItemIndex] = {
              ...prev[existingItemIndex],
              filter_items: sortedFilterItems,
            };

            return [...prev];
          }

          return [
            ...prev,
            {
              filter_order,
              filter_header,
              filter_items: [
                {
                  filter_header,
                  filter_detail,
                  filter_ref_id,
                },
              ],
            },
          ];
        }, [] as RhPortfolioFilterTransformedModel[]);
        return { data: a, rawData: response };
      },
    }),

    // SP: p_get_report_portfolio_details_by_id, View: vw_rh_report_portfolio_select_page_details_tab
    getRhPortfolioSelectedDetailsByRhpid: build.query<ReportHubSelectedDetailsModel, { userId: number; rhpId: number }>(
      {
        query: ({ userId, rhpId }) => ({
          url: baseUrl + `/portfolio/details/ref_user_id/${userId}/rh_report_portfolio_id/${rhpId}`,
        }),
        transformResponse: (res: ReportHubSelectedDetailsModel[]) => {
          return (res ?? [])[0];
        },
        providesTags: ['ReportPortfolio'],
      },
    ),

    // SP: p_get_report_portfolio_reporttag, View: vw_rh_report_portfolio_select_page_reporttag_tab
    getRhPortfolioSelectedReportsByRhpid: build.query<
      ReportHubSelectedReportsTabModel[],
      { userId: number; rhpId: number }
    >({
      query: ({ userId, rhpId }) => ({
        url: baseUrl + `/portfolio/reporttag/ref_user_id/${userId}/rh_report_portfolio_id/${rhpId}`,
      }),
      providesTags: ['ReportPortfolio'],
    }),

    getRhPortfolioReportByReportId: build.query<RhReportModel[], number>({
      query: rh_report_id => baseUrl + `/portfolio/report/rh_report_id/${rh_report_id}`,
    }),

    getRhPortfolioSelectedTagsByRhpid: build.query<ReportHubSelectedTagsTabModel[], { userId: number; rhpId: number }>({
      query: ({ userId, rhpId }) => ({
        url: baseUrl + `/portfolio/tags/ref_user_id/${userId}/rh_report_portfolio_id/${rhpId}`,
      }),
      providesTags: ['ReportPortfolio'],
    }),

    getRhPortfolioSelectedRolesByRhpid: build.query<ReportHubSelectedRolesTabModel[], { rhpId: number }>({
      query: ({ rhpId }) => ({
        url: baseUrl + `/portfolio/roles/tab/${rhpId}`,
      }),
      providesTags: ['ReportPortfolio'],
    }),

    getRhPortfolioSelectedUsersByRhpid: build.query<ReportHubSelectedUsersTabModel[], { rhpId: number }>({
      query: ({ rhpId }) => ({
        url: baseUrl + `/portfolio/users/tab/${rhpId}`,
      }),
      providesTags: ['ReportPortfolio'],
    }),

    getRhPortfolioSelectedServiceAccsByRhpid: build.query<ReportHubSelectedServiceAccountsTabModel[], { rhpId: number }>({
      query: ({ rhpId }) => ({
        url: baseUrl + `/portfolio/serviceaccounts/rh_report_portfolio_id/${rhpId}`,
      }),
      providesTags: ['ReportPortfolio'],
    }),

    getRhMyAccessList: build.query<RhReportHubMyAccessFirstDatasetModel[], number>({
      query: ref_user_id => baseUrl + `/my/access/${ref_user_id}`,
    }),

    getRhMyAccess2ndList: build.query<RhReportHubMyAccessSecondDatasetModel[], number>({
      query: ref_user_id => baseUrl + `/my/access/filters/view/${ref_user_id}`,
    }),

    getRhMyAccess3rdList: build.query<RhReportHubMyAccessThirdDatasetModel[], number>({
      query: ref_user_id => baseUrl + `/my/access/filters/${ref_user_id}`,
    }),

    getRhMyAccessSelectedDetailsById: build.query<
      ReportHubMyAccessDetailsModel,
      { ref_user_id: number; request_id: number; access_method: string }
    >({
      query: ({ ref_user_id, request_id, access_method }) =>
        baseUrl + `/my/access/details/${ref_user_id}/method/${access_method}/id/${request_id}`,
      transformResponse: (res: ReportHubMyAccessDetailsModel[]) => res[0],
    }),

    getRhMyAccessSelectedStatusById: build.query<
      ReportHubMyAccessStatusModel[],
      { ref_user_id: number; request_id: number; access_method: string }
    >({
      query: ({ ref_user_id, request_id, access_method }) =>
        baseUrl + `/my/access/details/status/${ref_user_id}/method/${access_method}/id/${request_id}`,
    }),

    getRhAllPortfolioStates: build.query<ReportPortfolioStateModel[], void>({
      query: () => baseUrl + '/portfolio/state/list',
    }),

    getRhAllReportStates: build.query<ReportStateModel[], void>({
      query: () => baseUrl + '/portfolio/report/state/list',
    }),

    getRhAllPortfolioTypes: build.query<ReportPortfolioTypeModel[], void>({
      query: () => baseUrl + '/portfolio/type/list',
    }),

    getRhUserHasAccessToPortfolio: build.query<{ has_access_flag: boolean }, { rhpId: number; userId: number }>({
      query: ({ rhpId, userId }) =>
        baseUrl + `/portfolio/check/access/rh_report_portfolio_id/${rhpId}/ref_user_id/${userId}`,
    }),

    addReportPortfolio: build.mutation<{ success: boolean }, AddReportHubPortfolioModel>({
      query: body => ({
        url: `/reporthubportfolio`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['ReportPortfolio'],
    }),

    updateReportPortfolio: build.mutation<{ success: boolean }, UpdateReportHubPortfolioModel>({
      query: body => ({
        url: `/reporthubportfolio`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['ReportPortfolio'],
    }),

    deleteReportPortfolio: build.mutation<{ success: boolean }, number>({
      query: rh_report_portfolio_id => ({
        url: `/reporthubportfolio/toggle/enable`,
        method: 'POST',
        body: {
          rh_report_portfolio_id,
          is_enable: false,
        },
      }),
      invalidatesTags: ['ReportPortfolio'],
    }),
  }),

  overrideExisting: true,
});

export const {
  useGetRhPortfolioListQuery,
  useLazyGetRhPortfolioListQuery,
  useGetRhPortfolioFilterListQuery,
  useGetRhPortfolioSelectedDetailsByRhpidQuery,
  useGetRhPortfolioSelectedReportsByRhpidQuery,
  useGetRhPortfolioReportByReportIdQuery,
  useGetRhPortfolioSelectedTagsByRhpidQuery,
  useGetRhPortfolioSelectedRolesByRhpidQuery,
  useGetRhPortfolioSelectedUsersByRhpidQuery,
  useGetRhPortfolioSelectedServiceAccsByRhpidQuery,
  useGetRhMyAccessListQuery,
  useGetRhMyAccess2ndListQuery,
  useGetRhMyAccess3rdListQuery,
  useGetRhMyAccessSelectedDetailsByIdQuery,
  useGetRhMyAccessSelectedStatusByIdQuery,
  useGetRhAllPortfolioStatesQuery,
  useGetRhAllReportStatesQuery,
  useGetRhAllPortfolioTypesQuery,

  useGetRhUserHasAccessToPortfolioQuery,

  useAddReportPortfolioMutation,
  useUpdateReportPortfolioMutation,
  useDeleteReportPortfolioMutation,
} = extendedApi;
