import { Box, Divider, Heading, HStack, Tab, TabList, TabPanels, Tabs, VStack } from '@chakra-ui/react';
import { createElement, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useGetLoreLanguageDetailsPageByLoreLanguageIdQuery } from '../../../../app/services/dme/api/glossary';
import LoreLanguageSelectedDetails from './Details';
import LoreLanguageSelectedDiscussion from './Discussion';
import LoreLanguageSelectedDomain from './Domain';
import LoreLanguageSelectedTags from './Tags';

type Props = {};
const tabs: {
  label: string;
  path: string;
  dataProperty: string;
  component: ({ data }: any) => JSX.Element;
}[] = [
  {
    label: 'Details',
    path: 'details',
    component: LoreLanguageSelectedDetails,
    dataProperty: 'details_tab',
  },
  {
    label: 'Squad',
    path: 'domain',
    component: LoreLanguageSelectedDomain,
    dataProperty: 'domain_tab',
  },
  {
    label: 'Tags',
    path: 'tag',
    component: LoreLanguageSelectedTags,
    dataProperty: 'tag_tab',
  },
  {
    label: 'Discussion',
    path: 'discussion',
    component: LoreLanguageSelectedDiscussion,
    dataProperty: '',
  },
];
const LoreLanguageSelected = (props: Props) => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [tabIndex, setTabIndex] = useState<number | undefined>();
  const llId: number = parseInt(params?.id || '');
  const { data, isLoading, isFetching } = useGetLoreLanguageDetailsPageByLoreLanguageIdQuery(llId, {
    skip: llId === 0,
  });

  useEffect(() => {
    if (tabIndex !== undefined && params.selectedTab !== tabs[tabIndex].path) {
      navigate('./../' + tabs[tabIndex].path + location.search);
    }
  }, [tabIndex]);

  useEffect(() => {
    const paramTab = params.selectedTab;

    if (paramTab !== undefined) {
      const pathIndex = tabs.findIndex(f => f.path === paramTab);
      pathIndex !== tabIndex && setTabIndex(pathIndex);
    }
  }, [params]);

  const handleTabsChange = (index: number) => {
    setTabIndex(index);
  };

  return (
    <VStack w="100%">
      <HStack>
        <Heading size="md">{data && data.details_tab[0].ll_term}</Heading>
      </HStack>
      <Divider />

      {tabIndex !== undefined && (
        <Tabs index={tabIndex} onChange={handleTabsChange}>
          <TabList>
            {tabs.map((m, i) => (
              <Tab key={i}>{m.label}</Tab>
            ))}
          </TabList>
          <TabPanels>
            <Box p={3}>
              {data && !isLoading && !isFetching ? (
                tabs[tabIndex] &&
                createElement(tabs[tabIndex].component, {
                  data: data[tabs[tabIndex].dataProperty as keyof typeof data],
                })
              ) : (
                <>Loading...</>
              )}
            </Box>
          </TabPanels>
        </Tabs>
      )}
    </VStack>
  );
};

export default LoreLanguageSelected;
