import { dmeApi } from './index';
import { RefEmploymentTypeModel } from './types';

const extendedApi = dmeApi.injectEndpoints({
  endpoints: builder => ({
    getRefEmploymentTypeList: builder.query<RefEmploymentTypeModel[], void>({
      query: () => `/refemploymenttype`,
    }),
  }),
  overrideExisting: false,
});

export const { useGetRefEmploymentTypeListQuery } = extendedApi;
