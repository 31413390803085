import { Heading, HStack, VStack } from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useGetDataProductSelectServiceAccountsByProductIdQuery } from '../../../../app/services/dme/api/dataProduct';
import CustomTable from '../../../../components/CustomTable';

const DataProductSelectedServiceAccounts = () => {
  const params = useParams();
  const [productId, setProductId] = useState(0);

  useEffect(() => {
    setProductId(+(params?.dataProductId || 0));
  }, [params]);

  const { data, isLoading } = useGetDataProductSelectServiceAccountsByProductIdQuery(productId, {
    skip: !productId,
  });

  //table header
  const Header = [
    {
        Header: 'Service Account Name',
        accessor: 'service_account_name',
        styles: { whiteSpace: 'initial', width: '300px'},
      },
      {
        Header: 'Role Name',
        accessor: 'role_name',
        styles: { whiteSpace: 'initial', maxWidth: '300px' },
      },
  ];

  return (
    <VStack spacing={5} p={1} pt={3} w="100%">
      <Heading size="sm">Service Accounts</Heading>
      {isLoading ? <p>Loading...</p> : displayTable()}
    </VStack>
  );

  function displayTable() {
    return !data ? null : (
      <HStack>
        <CustomTable
          variant="table"
          headers={Header}
          isLoading={isLoading}
          isFetching={isLoading}
          data={data || []}
          pageCount={0}
          pageSize={data ? data.length : 0}
          totalRecords={data ? data.length : 0}
          search=""
          onPageChange={index => {}}
          onPageSizeChange={size => {}}
          onPageSearch={search => {}}
          onSort={() => {}}
          hidePagination={true}
          tableSort={true}
          showNoRecords
        />
      </HStack>
    );
  }
};

export default DataProductSelectedServiceAccounts;
