import ThreeTwelveGroups from '../../pages/Groups/ThreeTwelveGroups';
import { ValueOf } from '../helpers/utilities';

export const StandardAppAccess = {
  DataLiteracyRead: 'dataliteracy.read',
  DataLiteracyWrite: 'dataliteracy.write',
  DataProductsRead: 'dataproducts.read',
  DataProductsWrite: 'dataproducts.write',
  ExploreDataProductsRead: 'exploredataproducts.read',
  ExploreDataProductsWrite: 'exploredataproducts.write',
  DataProductTagsRead: 'dataproducttags.read',
  DataProductTagsWrite: 'dataproducttags.write',
  DataProductMyAccessRead: 'dataproductmyaccess.read',
  DataProductMyAccessWrite: 'dataproductmyaccess.write',
  PlatformServicesRead: 'platformservices.read',
  PlatformServicesWrite: 'platformservices.write',
  ExplorePlatformServicesRead: 'exploreplatformservices.read',
  ExplorePlatformServicesWrite: 'exploreplatformservices.write',
  MyPlatformServicesRead: 'myplatformservices.read',
  MyPlatformServicesWrite: 'myplatformservices.write',
  LoreLanguageRead: 'lorelanguage.read',
  LoreLanguageWrite: 'lorelangulage.write',
  ExploreLoreLanguageRead: 'explorelorelanguage.read',
  ExploreLoreLanguageWrite: 'explorelorelanguage.write',
  EditLoreLanguageRead: 'editlorelanguage.read',
  EditLoreLanguageWrite: 'editlorelanguage.write',
  AddLoreLanguageRead: 'addlorelanguage.read',
  AddLoreLanguageWrite: 'addlorelanguage.write',
  MyRolesRead: 'myroles.read',
  MyRolesWrite: 'myroles.write',
  ExploreRolesRead: 'exploreroles.read',
  ExploreRolesWrite: 'exploreroles.write',
  AppAccessByRolesRead: 'appaccessbyroles.read',
  AppAccessByRolesWrite: 'appaccessbyroles.write',
  AddRoleRead: 'addrole.read',
  AddRoleWrite: 'addrole.write',
  AdminRead: 'admin.read',
  AdminWrite: 'admin.write',
  AdminUsersRead: 'adminusers.read',
  AdminUsersWrite: 'adminusers.write',
  AdminReviewFeedbackRead: 'adminreviewfeedback.read',
  AdminReviewFeedbackWrite: 'adminreviewfeedback.write',
  AdminAccessApprovalRead: 'adminaccessapproval.read',
  AdminAccessApprovalWrite: 'adminaccessapproval.write',
  EditorDataProductsRead: 'editordataproducts.read',
  EditorDataProductsWrite: 'editordataproducts.write',
  EditDataProductsRead: 'editdataproducts.read',
  EditDataProductsWrite: 'editdataproducts.write',
  AddDataProductsRead: 'adddataproducts.read',
  AddDataProductsWrite: 'adddataproducts.write',
  DomainRead: 'domain.read',
  DomainWrite: 'domain.write',
  ExploreDomainRead: 'exploredomain.read',
  ExploreDomainWrite: 'exploredomain.write',
  AddDomainRead: 'adddomain.read',
  AddDomainWrite: 'adddomain.write',
  EditDomainRead: 'editdomain.read',
  EditDomainWrite: 'editdomain.write',
  AccessApprovalRoleAccessTabRead: 'adminroleaccessapproval.read',
  AccessApprovalRoleAccessTabWrite: 'adminroleaccessapproval.write',
  AccessApprovalDataProductUsersTabRead: 'admindataproductusersapproval.read',
  AccessApprovalDataProductUsersTabWrite: 'admindataproductusersapproval.write',
  AccessApprovalDataProductRolesTabRead: 'admindataproductrolesapproval.read',
  AccessApprovalDataProductRolesTabWrite: 'admindataproductrolesapproval.write',
  LoreLinkRead: 'lorelink.read',
  LoreLinkWrite: 'lorelink.write',
  ExploreLoreLinkRead: 'explorelorelink.read',
  ExploreLoreLinkWrite: 'explorelorelink.write',
  EditLoreLinkRead: 'editlorelink.read',
  EditLoreLinkWrite: 'editlorelink.write',
  AddLoreLinkRead: 'addlorelink.read',
  AddLoreLinkWrite: 'addlorelink.write',
  CustomRequestsRead: 'customrequests.read',
  CustomRequestsWrite: 'customrequests.write',
  CreateCustomRequestRead: 'createcustomrequest.read',
  CreateCustomRequestWrite: 'createcustomrequest.write',
  MyCustomRequestRead: 'mycustomrequest.read',
  MyCustomRequestWrite: 'mycustomrequest.write',
  CustomRequestApprovalRead: 'customrequestapproval.read',
  CustomRequestApprovalWrite: 'customrequestapproval.write',
  TagsRead: 'tags.read',
  TagsWrite: 'tags.write',
  ExploreTagsRead: 'exploretags.read',
  ExploreTagsWrite: 'exploretags.write',
  AddTagsRead: 'addtags.read',
  AddTagsWrite: 'addtags.write',
  ReportHubRead: 'reporthub.read',
  ReportHubWrite: 'reporthub.write',
  ExploreReportPortfoliosRead: 'explorereportportfolios.read',
  ExploreReportPortfoliosWrite: 'explorereportportfolios.write',
  AddReportPortfoliosRead: 'addreportportfolios.read',
  AddReportPortfoliosWrite: 'addreportportfolios.write',
  ReportPortfoliosMyAccessRead: 'reportportfoliosmyaccess.read',
  ReportPortfoliosMyAccessWrite: 'reportportfoliosmyaccess.write',
  GovernanceOfficeRead: 'governanceoffice.read',
  GovernanceOfficeWrite: 'governanceoffice.write',
  MyEdsRequestsRead: 'myedsrequests.read',
  MyEdsRequestsWrite: 'myedsrequests.write',
  ExploreEdsRequestsRead: 'exploreedsrequests.read',
  ExploreEdsRequestsWrite: 'exploreedsrequests.write',
  EdsRequestsApprovalRead: 'edsrequestsapproval.read',
  EdsRequestsApprovalWrite: 'edsrequestsapproval.write',
  EmployeeOnboardingRead: 'employeeonboarding.read',
  EmployeeOnboardingWrite: 'employeeonboarding.write',
  CreateEORequestRead: 'createeorequest.read',
  CreateEORequestWrite: 'createeorequest.write',
  MyEORequestRead: 'myeorequest.read',
  MyEORequestWrite: 'myeorequest.write',
  EORequestApprovalRead: 'eorequestapproval.read',
  EORequestApprovalWrite: 'eorequestapproval.write',
  OffboardingRead: 'offboarding.read',
  OffboardingWrite: 'offboarding.write',
  GroupsRead: 'groups.read',
  GroupsWrite: 'groups.write',
  SquadsRead: 'squads.read',
  SquadsWrite: 'squads.write',
  FunctionalGroupsRead: 'functionalgroups.read',
  FunctionalGroupsWrite: 'functionalgroups.write',
  ThreeTwelveGroupsRead: 'threetwelvegroups.read',
  ThreeTwelveGroupsWrite: 'threetwelvegroups.write',
} as const;

// prettier-ignore
export const StandardAppAccessCombined = {
  DataLiteracy: [StandardAppAccess.DataLiteracyRead, StandardAppAccess.DataLiteracyWrite],
  DataProducts: [StandardAppAccess.DataProductsRead, StandardAppAccess.DataProductsWrite],
  ExploreDataProducts: [StandardAppAccess.ExploreDataProductsRead, StandardAppAccess.ExploreDataProductsWrite],
  DataProductTags: [StandardAppAccess.DataProductTagsRead, StandardAppAccess.DataProductTagsWrite],
  DataProductMyAccess: [StandardAppAccess.DataProductMyAccessRead, StandardAppAccess.DataProductMyAccessWrite],
  PlatformServices: [StandardAppAccess.PlatformServicesRead, StandardAppAccess.PlatformServicesWrite],
  ExplorePlatformServices: [StandardAppAccess.ExplorePlatformServicesRead, StandardAppAccess.ExplorePlatformServicesWrite],
  MyPlatformServices: [StandardAppAccess.MyPlatformServicesRead, StandardAppAccess.MyPlatformServicesWrite],
  LoreLanguage: [StandardAppAccess.LoreLanguageRead, StandardAppAccess.LoreLanguageWrite],
  ExploreLoreLanguage: [StandardAppAccess.ExploreLoreLanguageRead, StandardAppAccess.ExploreLoreLanguageWrite],
  EditLoreLanguage: [StandardAppAccess.EditLoreLanguageRead, StandardAppAccess.EditLoreLanguageWrite],
  AddLoreLanguage: [StandardAppAccess.AddLoreLanguageRead, StandardAppAccess.AddLoreLanguageWrite],
  MyRoles: [StandardAppAccess.MyRolesRead, StandardAppAccess.MyRolesWrite],
  ExploreRoles: [StandardAppAccess.ExploreRolesRead, StandardAppAccess.ExploreRolesWrite],
  AppAccessByRoles: [StandardAppAccess.AppAccessByRolesRead, StandardAppAccess.AppAccessByRolesWrite],
  AddRole: [StandardAppAccess.AddRoleRead, StandardAppAccess.AddRoleWrite],
  Admin: [StandardAppAccess.AdminRead, StandardAppAccess.AdminWrite],
  AdminUsers: [StandardAppAccess.AdminUsersRead, StandardAppAccess.AdminUsersWrite],
  AdminReviewFeedback: [StandardAppAccess.AdminReviewFeedbackRead, StandardAppAccess.AdminReviewFeedbackWrite],
  AdminAccessApproval: [StandardAppAccess.AdminAccessApprovalRead, StandardAppAccess.AdminAccessApprovalWrite],
  EditorDataProducts: [StandardAppAccess.EditorDataProductsRead, StandardAppAccess.EditorDataProductsWrite],
  EditDataProducts: [StandardAppAccess.EditDataProductsRead, StandardAppAccess.EditDataProductsWrite],
  AddDataProducts: [StandardAppAccess.AddDataProductsRead, StandardAppAccess.AddDataProductsWrite],
  Domain: [StandardAppAccess.DomainRead, StandardAppAccess.DomainWrite],
  ExploreDomain: [StandardAppAccess.ExploreDomainRead, StandardAppAccess.ExploreDomainWrite],
  AddDomain: [StandardAppAccess.AddDomainRead, StandardAppAccess.AddDomainWrite],
  EditDomain: [StandardAppAccess.EditDomainRead, StandardAppAccess.EditDomainWrite],
  AccessApprovalRoleAccessTab: [StandardAppAccess.AccessApprovalRoleAccessTabRead, StandardAppAccess.AccessApprovalRoleAccessTabWrite],
  AccessApprovalDataProductUsersTab: [StandardAppAccess.AccessApprovalDataProductUsersTabRead, StandardAppAccess.AccessApprovalDataProductUsersTabWrite],
  AccessApprovalDataProductRolesTab: [StandardAppAccess.AccessApprovalDataProductRolesTabRead, StandardAppAccess.AccessApprovalDataProductRolesTabWrite],
  LoreLink: [StandardAppAccess.LoreLinkRead,StandardAppAccess.LoreLinkWrite],
  ExploreLoreLink: [StandardAppAccess.ExploreLoreLinkRead,StandardAppAccess.ExploreLoreLinkWrite],
  EditLoreLink: [StandardAppAccess.EditLoreLinkRead,StandardAppAccess.EditLoreLinkWrite],
  AddLoreLink: [StandardAppAccess.AddLoreLinkRead,StandardAppAccess.AddLoreLinkWrite],
  CustomRequests: [StandardAppAccess.CustomRequestsRead, StandardAppAccess.CustomRequestsWrite],
  CreateCustomRequest: [StandardAppAccess.CreateCustomRequestRead, StandardAppAccess.CreateCustomRequestWrite],
  MyCustomRequest: [StandardAppAccess.MyCustomRequestRead, StandardAppAccess.MyCustomRequestWrite],
  CustomRequestApproval: [StandardAppAccess.CustomRequestApprovalRead, StandardAppAccess.CustomRequestApprovalWrite],
  Tags: [StandardAppAccess.TagsRead, StandardAppAccess.TagsWrite],
  ExploreTags: [StandardAppAccess.ExploreTagsRead, StandardAppAccess.ExploreTagsWrite],
  AddTags: [StandardAppAccess.AddTagsRead, StandardAppAccess.AddTagsWrite],
  ReportHub: [StandardAppAccess.ReportHubRead, StandardAppAccess.ReportHubWrite],
  ExploreReportPortfolios: [StandardAppAccess.ExploreReportPortfoliosRead, StandardAppAccess.ExploreReportPortfoliosWrite],
  AddReportPortfolios: [StandardAppAccess.AddReportPortfoliosRead, StandardAppAccess.AddReportPortfoliosWrite],
  ReportPortfoliosMyAccess: [StandardAppAccess.ReportPortfoliosMyAccessRead, StandardAppAccess.ReportPortfoliosMyAccessWrite],
  GovernanceOffice: [StandardAppAccess.GovernanceOfficeRead, StandardAppAccess.GovernanceOfficeWrite],
  MyEdsRequests: [StandardAppAccess.MyEdsRequestsRead, StandardAppAccess.MyEdsRequestsWrite],
  ExploreEdsRequests: [StandardAppAccess.ExploreEdsRequestsRead, StandardAppAccess.ExploreEdsRequestsWrite],
  EdsRequestsApproval: [StandardAppAccess.EdsRequestsApprovalRead, StandardAppAccess.EdsRequestsApprovalWrite],
  EmployeeOnboarding: [StandardAppAccess.EmployeeOnboardingRead, StandardAppAccess.EmployeeOnboardingWrite],
  CreateEORequest: [StandardAppAccess.CreateEORequestRead, StandardAppAccess.CreateEORequestWrite],
  MyEORequest: [StandardAppAccess.MyEORequestRead, StandardAppAccess.MyEORequestWrite],
  EORequestApproval: [StandardAppAccess.EORequestApprovalRead, StandardAppAccess.EORequestApprovalWrite],
  Offboarding: [StandardAppAccess.OffboardingRead, StandardAppAccess.OffboardingWrite],
  Groups: [StandardAppAccess.GroupsRead, StandardAppAccess.GroupsWrite],
  Squads: [StandardAppAccess.SquadsRead, StandardAppAccess.SquadsWrite],
  FunctionalGroups: [StandardAppAccess.FunctionalGroupsRead, StandardAppAccess.FunctionalGroupsWrite],
  ThreeTwelveGroups: [StandardAppAccess.ThreeTwelveGroupsRead, StandardAppAccess.ThreeTwelveGroupsWrite],
};

export const SpecialRoles = {} as const;

export const AppAccess = {
  ...StandardAppAccess,
  ...StandardAppAccessCombined,
  ...SpecialRoles,
  standard: { ...StandardAppAccess, ...StandardAppAccessCombined },
  special: SpecialRoles,
};

export type AppAccessName = ValueOf<typeof StandardAppAccess> | ValueOf<typeof SpecialRoles>;
export type AppAuthRequiredAppAccess = AppAccessName | Array<AppAccessName> | '*';
