import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Spacer,
  Text,
  Textarea,
  Tooltip,
  VStack,
} from '@chakra-ui/react';
import { Select, chakraComponents } from 'chakra-react-select';
import { FocusEvent } from 'react';
import { FiRefreshCcw } from 'react-icons/fi';
import { ValueOf } from '../../../../app/helpers/utilities';
import { useGetDataProductStateListQuery } from '../../../../app/services/dme/api/dataProduct';
import { useGetRefDataProductTypeListQuery } from '../../../../app/services/dme/api/refDataProductType';
import { useGetRefDomainListQuery } from '../../../../app/services/dme/api/refDomain';
import { DataProductSelectPageDetailsModel } from '../../../../app/services/dme/api/types';
import { useGetRefUserListQuery } from '../../../../app/services/dme/api/user';
import { EditDataProductModel } from '../../../../app/services/types';
import { DefaultInnerHtmlStyle } from '../../../../app/constants';

type Props = {
  initialValues: EditDataProductModel;
  data: EditDataProductModel;
  onChangeData: (field: keyof EditDataProductModel, data: ValueOf<EditDataProductModel>, isReset?: boolean) => void;
  isSubmitting: boolean;
  errors: any;
  touched: any;
  title: string;
  tabErrorIndex: number;
};

const DataProductSelectedDetails = ({ data, onChangeData, errors, touched, tabErrorIndex, initialValues }: Props) => {
  const { data: domainData, isLoading: isLoadingDomain, isFetching: isFetchingDomain } = useGetRefDomainListQuery();

  const {
    data: developerData,
    isLoading: isLoadingDeveloper,
    isFetching: isFetchingDeveloper,
  } = useGetRefUserListQuery({ disabled_flag: false });

  const {
    data: dpTypeData,
    isLoading: isLoadingdpType,
    isFetching: isFetchingdpType,
  } = useGetRefDataProductTypeListQuery();

  const {
    data: stateData,
    isLoading: isLoadingdpState,
    isFetching: isFetchingdpState,
  } = useGetDataProductStateListQuery();

  const onBlur = (
    e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: keyof DataProductSelectPageDetailsModel,
  ) => {
    data.detailModel &&
      onChangeData('detailModel', {
        ...data.detailModel,
        [field]: e.target.value.trim(),
      });
  };

  const selectedState = stateData?.find(f => f.dp_data_product_state_id === data.detailModel?.dp_data_product_state_id);

  return (
    <VStack spacing={5} p={1} pt={3}>
      {!data.detailModel ? (
        <></>
      ) : (
        <VStack w="container.md">
          <HStack>
            <Button
              size="sm"
              leftIcon={<FiRefreshCcw />}
              onClick={() => {
                data.detailModel && onChangeData('detailModel', data.detailModel, true);
              }}
            >
              Refresh
            </Button>
          </HStack>
          <Spacer />
          <FormControl
            isInvalid={
              (!!(
                errors.detailModel &&
                (errors.detailModel as unknown as DataProductSelectPageDetailsModel)?.data_product_name
              ) &&
                !!(
                  touched.detailModel &&
                  (touched.detailModel as unknown as DataProductSelectPageDetailsModel)?.data_product_name
                )) ||
              tabErrorIndex === 0
            }
            display="flex"
          >
            <FormLabel minW="150px">Name</FormLabel>
            <Input
              size="sm"
              value={data.detailModel.data_product_name}
              onChange={e =>
                data.detailModel &&
                onChangeData('detailModel', {
                  ...data.detailModel,
                  data_product_name: e.target.value,
                })
              }
              onBlur={e => onBlur(e, 'data_product_name')}
            />
            <FormErrorMessage></FormErrorMessage>
          </FormControl>
          <FormControl
            isInvalid={
              !!(
                errors.detailModel &&
                (errors.detailModel as unknown as DataProductSelectPageDetailsModel)?.data_product_desc
              ) &&
              !!(
                touched.detailModel &&
                (touched.detailModel as unknown as DataProductSelectPageDetailsModel)?.data_product_desc
              )
            }
            display="flex"
          >
            <FormLabel minW="150px">Description</FormLabel>
            <Textarea
              size="sm"
              value={data.detailModel.data_product_desc}
              onChange={e =>
                data.detailModel &&
                onChangeData('detailModel', {
                  ...data.detailModel,
                  data_product_desc: e.target.value,
                })
              }
              onBlur={e => onBlur(e, 'data_product_desc')}
            ></Textarea>
            <FormErrorMessage></FormErrorMessage>
          </FormControl>
          <FormControl
            isInvalid={
              !!(
                errors.detailModel &&
                (errors.detailModel as unknown as DataProductSelectPageDetailsModel)?.ref_domain_id
              ) &&
              !!(
                touched.detailModel &&
                (touched.detailModel as unknown as DataProductSelectPageDetailsModel)?.ref_domain_id
              )
            }
            display="flex"
          >
            <FormLabel minW="150px">Squad</FormLabel>
            {isLoadingDomain ? (
              <Input readOnly size="sm" value="Loading..." />
            ) : (
              <Box w="100%">
                <Select
                  size="sm"
                  useBasicStyles
                  value={
                    domainData
                      ? {
                          label: domainData.find(f => f.ref_domain_id === data.detailModel?.ref_domain_id)?.domain_name,
                          value: domainData.find(f => f.ref_domain_id === data.detailModel?.ref_domain_id)
                            ?.ref_domain_id,
                        }
                      : {}
                  }
                  options={
                    domainData
                      ? domainData.map(m => {
                          return {
                            label: m.domain_name,
                            value: m.ref_domain_id,
                          };
                        })
                      : undefined
                  }
                  onChange={e => {
                    data.detailModel &&
                      onChangeData('detailModel', {
                        ...data.detailModel,
                        ref_domain_id: e?.value,
                      });
                  }}
                  maxMenuHeight={250}
                  menuPortalTarget={document.body}
                />
              </Box>
            )}

            <FormErrorMessage></FormErrorMessage>
          </FormControl>
          <FormControl
            isInvalid={
              !!(
                errors.detailModel &&
                (errors.detailModel as unknown as DataProductSelectPageDetailsModel)?.data_product_type_id
              ) &&
              !!(
                touched.detailModel &&
                (touched.detailModel as unknown as DataProductSelectPageDetailsModel)?.data_product_type_id
              )
            }
            display="flex"
          >
            <FormLabel minW="150px">Data Product Type</FormLabel>
            {isLoadingdpType ? (
              <Input readOnly size="sm" value="Loading..." />
            ) : (
              <Box w="100%">
                <Select
                  size="sm"
                  useBasicStyles
                  value={
                    dpTypeData
                      ? {
                          label: dpTypeData.find(
                            f => f.ref_data_product_type_id === data.detailModel?.data_product_type_id,
                          )?.data_product_type,
                          value: dpTypeData.find(
                            f => f.ref_data_product_type_id === data.detailModel?.data_product_type_id,
                          )?.ref_data_product_type_id,
                        }
                      : {}
                  }
                  options={
                    dpTypeData
                      ? dpTypeData.map(m => {
                          return {
                            label: m.data_product_type,
                            value: m.ref_data_product_type_id,
                          };
                        })
                      : undefined
                  }
                  onChange={e => {
                    data.detailModel &&
                      onChangeData('detailModel', {
                        ...data.detailModel,
                        data_product_type_id: e?.value || 0,
                      });
                  }}
                  maxMenuHeight={250}
                  menuPortalTarget={document.body}
                />
              </Box>
            )}

            <FormErrorMessage></FormErrorMessage>
          </FormControl>
          <FormControl
            isInvalid={
              !!(
                errors.detailModel &&
                (errors.detailModel as unknown as DataProductSelectPageDetailsModel)?.developer_user_id
              ) &&
              !!(
                touched.detailModel &&
                (touched.detailModel as unknown as DataProductSelectPageDetailsModel)?.developer_user_id
              )
            }
            display="flex"
          >
            <FormLabel minW="150px">Developer</FormLabel>
            {isLoadingDeveloper ? (
              <Input readOnly size="sm" value="Loading..." />
            ) : (
              <Box w="100%">
                <Select
                  size="sm"
                  useBasicStyles
                  value={
                    developerData
                      ? {
                          label: `${
                            developerData.find(f => f.ref_user_id === data.detailModel?.developer_user_id)
                              ?.first_name || ''
                          } ${
                            developerData.find(f => f.ref_user_id === data.detailModel?.developer_user_id)?.last_name ||
                            ''
                          }`,
                          value: developerData.find(f => f.ref_user_id === data.detailModel?.developer_user_id)
                            ?.ref_user_id,
                        }
                      : {}
                  }
                  options={
                    developerData
                      ? developerData
                          .filter(f => f.first_name && f.last_name)
                          .map(m => {
                            return {
                              label: `${m.first_name} ${m.last_name}`,
                              value: m.ref_user_id,
                            };
                          })
                      : undefined
                  }
                  onChange={e => {
                    data.detailModel &&
                      onChangeData('detailModel', {
                        ...data.detailModel,
                        developer_user_id: e?.value || 0,
                      });
                  }}
                  maxMenuHeight={250}
                  menuPortalTarget={document.body}
                />
              </Box>
            )}
            <FormErrorMessage></FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={false} display="flex">
            <FormLabel minW="150px">PHI</FormLabel>
            <Box minW="100px">
              <Select
                size="sm"
                useBasicStyles
                value={{
                  label: data.detailModel?.phi_present_flag ? 'Yes' : 'No',
                  value: data.detailModel?.phi_present_flag ? true : false,
                }}
                options={[
                  {
                    label: 'Yes',
                    value: true,
                  },
                  {
                    label: 'No',
                    value: false,
                  },
                ]}
                onChange={e => {
                  data.detailModel &&
                    onChangeData('detailModel', {
                      ...data.detailModel,
                      phi_present_flag: e?.value || false,
                    });
                }}
                maxMenuHeight={250}
                menuPortalTarget={document.body}
              />
            </Box>
            <FormErrorMessage></FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={false} display="flex">
            <FormLabel minW="150px">PII</FormLabel>
            <Box minW="100px">
              <Select
                size="sm"
                useBasicStyles
                value={{
                  label: data.detailModel?.pii_present_flag ? 'Yes' : 'No',
                  value: data.detailModel?.pii_present_flag ? true : false,
                }}
                options={[
                  {
                    label: 'Yes',
                    value: true,
                  },
                  {
                    label: 'No',
                    value: false,
                  },
                ]}
                onChange={e => {
                  data.detailModel &&
                    onChangeData('detailModel', {
                      ...data.detailModel,
                      pii_present_flag: e?.value || false,
                    });
                }}
                maxMenuHeight={250}
                menuPortalTarget={document.body}
              />
            </Box>
            <FormErrorMessage></FormErrorMessage>
          </FormControl>

          <FormControl
            isInvalid={
              (!!(
                errors.detailModel &&
                (errors.detailModel as unknown as DataProductSelectPageDetailsModel)?.dp_data_product_state_id
              ) &&
                !!(
                  touched.detailModel &&
                  (touched.detailModel as unknown as DataProductSelectPageDetailsModel)?.dp_data_product_state_id
                )) ||
              tabErrorIndex === 0
            }
            display="flex"
          >
            <FormLabel minW="150px">State</FormLabel>
            <Box minW="150px">
              {!stateData || isLoadingdpState ? (
                <Input readOnly size="sm" value="Loading..." />
              ) : (
                <Select
                  placeholder="Select state..."
                  chakraStyles={{
                    container: (provided, state) => {
                      return {
                        ...provided,
                        color: selectedState ? selectedState.dp_state_color : undefined,
                      };
                    },
                    control: (provided, state) => {
                      return {
                        ...provided,
                        borderColor: selectedState ? selectedState.dp_state_color : 'inherit',
                        _focusVisible: 'none',
                        _hover: 'none',
                      };
                    },
                    option: (provided, state) => {
                      const isSelected = data.detailModel?.dp_data_product_state_id === state.data.value;
                      return {
                        ...provided,
                        color: isSelected ? 'white' : state.data.data.dp_state_color, // if selected white
                        bg: isSelected ? state.data.data.dp_state_color : undefined, // if selected state.data.data.dp_state_color
                      };
                    },
                  }}
                  useBasicStyles
                  size="sm"
                  options={stateData.map(m => ({
                    value: m.dp_data_product_state_id,
                    label: m.dp_state_name,
                    data: m,
                  }))}
                  value={(() => {
                    return selectedState
                      ? {
                          value: selectedState.dp_data_product_state_id,
                          label: selectedState.dp_state_name,
                          data: selectedState,
                        }
                      : undefined;
                  })()}
                  onChange={e => {
                    if (e && data.detailModel) {
                      const updatedData = {
                        ...data.detailModel,
                        dp_data_product_state_id: e.value,
                      };

                      if (e.value === initialValues.detailModel?.dp_data_product_state_id) {
                        updatedData.updateStatePostMessage = undefined;
                      }

                      onChangeData('detailModel', updatedData);
                    }
                  }}
                  components={{
                    Option: props => {
                      return (
                        <chakraComponents.Option {...props}>
                          <Box w="100%">
                            <Tooltip
                              label={
                                <Box
                                  dangerouslySetInnerHTML={{ __html: props.data.data.dp_state_description }}
                                  sx={DefaultInnerHtmlStyle}
                                ></Box>
                              }
                              placement="right"
                            >
                              <Text>{props.label}</Text>
                            </Tooltip>
                          </Box>
                        </chakraComponents.Option>
                      );
                    },
                    SingleValue: props => {
                      return (
                        <chakraComponents.SingleValue {...props}>
                          <Tooltip
                            label={
                              <Box
                                dangerouslySetInnerHTML={{ __html: props.data.data.dp_state_description }}
                                sx={DefaultInnerHtmlStyle}
                              ></Box>
                            }
                            placement="right"
                          >
                            {props.data.label}
                          </Tooltip>
                        </chakraComponents.SingleValue>
                      );
                    },
                  }}
                />
              )}
            </Box>
            <FormErrorMessage></FormErrorMessage>
          </FormControl>
          {data.detailModel?.dp_data_product_state_id &&
            data.detailModel?.dp_data_product_state_id !== initialValues.detailModel?.dp_data_product_state_id && (
              <FormControl
                isInvalid={
                  (!!(
                    errors.detailModel &&
                    (errors.detailModel as unknown as EditDataProductModel['detailModel'])?.updateStatePostMessage
                  ) &&
                    !!(
                      touched.detailModel &&
                      (touched.detailModel as unknown as EditDataProductModel['detailModel'])?.updateStatePostMessage
                    )) ||
                  tabErrorIndex === 0
                }
                display="flex"
              >
                <FormLabel minW="150px">Change State Message</FormLabel>
                <Textarea
                  size="sm"
                  value={data.detailModel.updateStatePostMessage}
                  onChange={e =>
                    data.detailModel &&
                    onChangeData('detailModel', {
                      ...data.detailModel,
                      updateStatePostMessage: e.target.value,
                    })
                  }
                  onBlur={e =>
                    data.detailModel &&
                    onChangeData('detailModel', {
                      ...data.detailModel,
                      updateStatePostMessage: e.target.value,
                    })
                  }
                />
                <FormErrorMessage></FormErrorMessage>
              </FormControl>
            )}
        </VStack>
      )}
    </VStack>
  );
};

export default DataProductSelectedDetails;
