import { Box } from '@chakra-ui/react';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { CellProps } from 'react-table';
import { convertUtcToLocal } from '../../../app/helpers/dateHelper';
import { useGetRhMyAccessListQuery } from '../../../app/services/dme/api/reportHub';
import { RhReportHubMyAccessFirstDatasetModel } from '../../../app/services/dme/api/types';
import { useAppSelector } from '../../../app/state/hooks';
import { CustomTableHeader } from '../../../app/types/appType';
import CustomTable from '../../../components/CustomTable';

type Props = {
  data: RhReportHubMyAccessFirstDatasetModel[];
  isLoading: boolean;
};

const ReportHubMyAccess: FC<Props> = ({ data, isLoading }) => {
  const { logonUser } = useAppSelector(s => s.user);
  const navigate = useNavigate();

  const Header: CustomTableHeader<RhReportHubMyAccessFirstDatasetModel> = [
    {
      Header: 'Status',
      accessor: 'access_request_status',
    },
    {
      Header: 'Method',
      accessor: 'access_method',
    },
    {
      Header: 'Expiration Date',
      isSortable: false,
      Cell: ({ row: { original } }: CellProps<RhReportHubMyAccessFirstDatasetModel>) => {
        return <>{convertUtcToLocal(original.access_expiration_datetime_utc) || '-'}</>;
      },
    },
    {
      Header: 'Squad',
      accessor: 'domain_name',
    },
    {
      Header: 'Portfolio Name',
      accessor: 'report_portfolio_name',
    },
  ];

  return (
    <Box p={3} borderWidth="1px" borderRadius="lg">
      <CustomTable
        variant="table"
        headers={Header}
        isLoading={isLoading}
        isFetching={isLoading}
        data={data || []}
        pageCount={1}
        pageSize={data ? data.length : 10}
        totalRecords={data ? data.length : 10}
        search=""
        onPageChange={index => {}}
        onPageSizeChange={size => {}}
        onPageSearch={search => {}}
        onSort={() => {}}
        hidePagination={true}
        tableSort={true}
        showNoRecords
        onRowClick={entry => {
          navigate(`./selected/access-method/${entry.access_method}/id/${entry.request_id}/details`);
        }}
      />
    </Box>
  );
};

export default ReportHubMyAccess;
