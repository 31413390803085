import { Box, ButtonGroup, IconButton, Tooltip } from '@chakra-ui/react';
import { FaUnlockAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { convertUtcToLocal } from '../../../../app/helpers/dateHelper';
import { DataProductMyAccessFirstDatasetModel } from '../../../../app/services/dme/api/types';
import { useAppSelector } from '../../../../app/state/hooks';
import CustomTable from '../../../../components/CustomTable';
import RequestAccessDialog from '../../RequestAccess';

type Props = {
  data: DataProductMyAccessFirstDatasetModel[];
  isLoading: boolean;
};

const MyAccessResultData = ({ data, isLoading }: Props) => {
  const navigate = useNavigate();
  const { logonUser } = useAppSelector(s => s.user);
  const Header = [
    {
      Header: 'Request Access',
      styles: { textAlign: 'center' },
      isSortable: false,
      Cell: ({ row: { original } }: { row: { original: DataProductMyAccessFirstDatasetModel } }) => {
        return (
          <>
            <ButtonGroup spacing={2}>
              {logonUser && (
                <RequestAccessDialog
                  email={logonUser?.email}
                  ref_role_or_user_id={{
                    access_type: original.access_method.toLowerCase() === 'user access' ? 'user' : 'role',
                    ref_role_id: (original.access_method.toLowerCase() === 'user access'
                      ? original.ref_user_id
                      : original.ref_role_id
                    ).toString(),
                  }}
                  dp_data_product_id={original.dp_data_product_id}
                  dp_data_product_delivery_mode_id={original.dp_data_product_delivery_mode_id}
                  access_request_business_justification={original.access_request_business_justification}
                  triggerElement={
                    <Tooltip label="Request Access">
                      <IconButton
                        variant="link"
                        aria-label="Request Access"
                        icon={<FaUnlockAlt />}
                        minWidth={1}
                        colorScheme="brand.main"
                      />
                    </Tooltip>
                  }
                />
              )}
            </ButtonGroup>
          </>
        );
      },
    },
    {
      Header: 'Status',
      accessor: 'access_request_status',
    },
    {
      Header: 'Method',
      accessor: 'access_method',
    },
    {
      Header: 'Expiration Date',
      accessor: 'access_expiration_datetime_utc',
      Cell: ({ row: { original } }: { row: { original: DataProductMyAccessFirstDatasetModel } }) => {
        return <>{convertUtcToLocal(original.access_expiration_datetime_utc) || '-'}</>;
      },
    },
    {
      Header: 'Squad',
      accessor: 'domain_name',
    },
    {
      Header: 'Data Product Name',
      accessor: 'data_product_name',
      styles: { whiteSpace: 'nowrap' },
    },
    {
      Header: 'Delivery Mode',
      accessor: 'delivery_mode',
    },
  ];

  const onClickRow = (row: DataProductMyAccessFirstDatasetModel) => {
    navigate(`../detail/accessmethod/${row.access_method}/requestid/${row.request_id}/detail`);
  };

  return (
    <Box p={3} borderWidth="1px" borderRadius="lg">
      <CustomTable
        variant="table"
        headers={Header}
        isLoading={isLoading}
        isFetching={isLoading}
        data={data || []}
        pageCount={0}
        pageSize={5}
        totalRecords={data ? data.length : 0}
        search=""
        onPageChange={() => {}}
        onPageSizeChange={() => {}}
        onPageSearch={() => {}}
        onSort={() => {}}
        hidePagination={true}
        tableSort={true}
        onRowClick={row => onClickRow(row)}
      />
    </Box>
  );
};

export default MyAccessResultData;
